import LegalPage, { LegalPageProps } from 'ui/customer-support/components/legal-page';

type Props = {
  data: LegalPageProps;
};

const LegalPageTastic = ({ data }: Props) => {
  return <LegalPage {...data} />;
};

export default LegalPageTastic;
