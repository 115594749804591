import React, { useEffect } from 'react';
import { RadioButton, LoadingIndicator } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { ShippingMethod } from 'shared/types/ngcCart';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './shippingMethod.module.scss';
import { ShippingMethodError } from '../../../index';
import { renderShippingPrice } from '../../../utils';
import CheckoutNotification from '../../checkoutNotification';

type Props = {
  fetchShippingMethods: () => void;
  onChangeHandler: (shippingMethodOption: ShippingMethod, retry: boolean) => void;
  shippingMethodsListData: ShippingMethod[];
  isLoading: boolean;
  selectedShippingMethod: ShippingMethod;
  setSelectedShippingMethod: (shippingMethod: ShippingMethod) => void;
  shippingMethodError: ShippingMethodError;
};

const ShippingMethods = ({
  onChangeHandler,
  shippingMethodsListData,
  isLoading,
  selectedShippingMethod,
  setSelectedShippingMethod,
  shippingMethodError,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { cart } = useCart();

  useEffect(() => {
    if (Array.isArray(shippingMethodsListData) && shippingMethodsListData.length > 0) {
      findSelectedShippingMethod();
    }
  }, [shippingMethodsListData]);

  const findSelectedShippingMethod = () => {
    if (cart?.shippingInfo?.id !== undefined) {
      setSelectedShippingMethod(cart?.shippingInfo as ShippingMethod);
      onChangeHandler(cart?.shippingInfo, false);
    } else {
      setSelectedShippingMethod(shippingMethodsListData[0]);
      onChangeHandler(shippingMethodsListData[0], false);
    }
  };

  const renderGetShippingMethodsErrorNotification = () => {
    return (
      <div data-testid="place-order__error-modal-container_test-id">
        <CheckoutNotification
          message={formatMessage({
            id: 'cart.checkout.fetchShippingMethods.errorMessage',
            defaultMessage: `We're currently unable to load shipping methods.`,
          })}
          show={shippingMethodError.isError}
          showTryAgain={shippingMethodError.showTryAgain}
          tryAgainCallback={shippingMethodError.callBack}
        />
      </div>
    );
  };

  const renderUpdateShippingMethodErrorNotification = () => {
    return (
      <div data-testid="place-order__error-modal-container_test-id">
        <CheckoutNotification
          message={formatMessage({
            id: 'cart.checkout.updateShippingMethod.errorMessage',
            defaultMessage: `We were unable to save your selected shipping method.`,
          })}
          show={shippingMethodError.isError}
          showTryAgain={shippingMethodError.showTryAgain}
          tryAgainCallback={shippingMethodError.callBack}
        />
      </div>
    );
  };

  const renderContent = () => {
    // Render get shipping error notification when getShippingMethods call fail and do not display list
    if (shippingMethodError.isError === true && shippingMethodError.errorType === 'getShippingMethod') {
      return renderGetShippingMethodsErrorNotification();
    } else {
      return (
        <>
          {/* Render update shipping method error notification when updateShippingMethod call fail and render shipping methods also */}
          {shippingMethodError.isError === true &&
            shippingMethodError.errorType === 'updateShippingMethod' &&
            renderUpdateShippingMethodErrorNotification()}
          <div className={styles.shippingMethodContent} data-testid="shipping-method__list-container_test-id">
            {shippingMethodsListData &&
              shippingMethodsListData.length > 0 &&
              shippingMethodsListData.map((item, index) => {
                return (
                  <RadioButton
                    data-testid={`shipping-methods__radio-${index}_test-id`}
                    id={`shipping-methods__radio-${index}`}
                    key={index}
                    name="radio"
                    classes={{
                      icon: 'radioDemoIcon',
                      label: 'radioDemoLabel',
                      labelContent: 'flex flex-col gap-4',
                    }}
                    checked={item.id === selectedShippingMethod?.id}
                    onChange={() => onChangeHandler(item, false)}
                  >
                    <div className="lll-text-body-1 lll-font-weight-medium">{item.name}</div>
                    <div className="lll-text-body-2">
                      <span>{item.description}</span>{' '}
                      <span className="lll-font-weight-medium">
                        (
                        {renderShippingPrice(
                          item?.shippingPrice?.total,
                          formatMessage({ id: 'freeShipping', defaultMessage: 'FREE' }),
                        )}
                        )
                      </span>
                    </div>
                  </RadioButton>
                );
              })}
          </div>
        </>
      );
    }
  };

  return (
    <div className={cs(styles.shippingMethod)} data-testid="shipping-method__container_test-id">
      <div className="lll-text-small">
        {formatMessage({ id: 'shipping.method', defaultMessage: 'Shipping method' })}
      </div>
      {isLoading ? (
        <div className={styles.loaderContainer} data-testid="shipping-method__loading-indicator_test-id">
          <LoadingIndicator color="red" />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default ShippingMethods;
