import { Heading } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { PaymentMethodPayload } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { isEmpty } from 'helpers/utils/isEmpty';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import styles from './PTPayment.module.scss';
import OrderTypeList from './orderTypeList';
import { CheckoutPaymentNotificationType } from '..';
import CheckoutNotification from '../../checkoutNotification';

type Props = {
  updatePaymentMethod: (data: PaymentMethodPayload) => void;
  notifications: CheckoutPaymentNotificationType;
};

const PTPayment = ({ updatePaymentMethod, notifications }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { isFeatureEnabled } = useFeatureFlags();
  const isRetryUpdatePaymentFeatureEnabled = isFeatureEnabled(FEATURE_FLAG_LIST.RETRY_UPDATE_PAYMENT_METHOD);
  const notificationData = isRetryUpdatePaymentFeatureEnabled ? notifications.otherError : notifications.ptError || '';

  return (
    <div className={styles.container} data-testid="cc-payment__container_test-id">
      <CheckoutNotification
        show={!isEmpty(notificationData)}
        {...notificationData}
        className={cx({ [styles.notification]: isEmpty(notifications.fetchCreditCardsError) })}
        name={'credit-cards'}
      />
      {
        <>
          <Heading tag="h4" className={styles.subtext} data-testid="cc-payment__heading_test-id">
            {formatMessage({
              id: 'select.pt.txt',
              defaultMessage: 'Please select order type',
            })}
          </Heading>
          <OrderTypeList updatePaymentMethod={updatePaymentMethod} />
        </>
      }
    </div>
  );
};

export default PTPayment;
