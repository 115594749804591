import { useEffect, useState } from 'react';
import { RadioButton } from '@lululemon/ecom-pattern-library';
import { Formik, Form, useFormikContext, FormikValues } from 'formik';
import { PaymentMethodPayload, useCart } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFormattedAddressinMultiline } from 'helpers/utils/formatAddress';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import { useBusinessUnit } from 'frontastic';
import styles from './PTOrderList.module.scss';

type Props = {
  updatePaymentMethod: (data: PaymentMethodPayload) => void;
};

export interface OrderType {
  [key: string]: string;
}

const SubmitOrderTypeOnChange = () => {
  const { values, submitForm, submitCount, dirty } = useFormikContext();

  const initialRender = !dirty && !submitCount;

  useEffect(() => {
    !initialRender && submitForm();
  }, [values, initialRender]);

  return null;
};

const OrderTypeList = ({ updatePaymentMethod }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { cart, ptPaymentMethod, ptOrderTypes } = useCart();
  const { billingAddresses } = useBusinessUnit();
  const { isFeatureEnabled } = useFeatureFlags();
  const isCSVFeatureFlagEnabled = isFeatureEnabled(FEATURE_FLAG_LIST.ORDER_DETAILS_CSV);
  const allOrderTypes: any = ptOrderTypes;

  const allOrderKeys = Object.keys(allOrderTypes);

  const updateSelectedOrderType = (values: FormikValues) => {
    if (allOrderKeys.includes(values.selectOrderType) && values.selectOrderType !== selectedKey) {
      setSelectedKey(values.selectOrderType);
      updatePaymentMethod({
        paymentMethod: ptPaymentMethod,
        billingAddressId: billingAddresses[0].addressId,
        ptOrderType: values.selectOrderType,
      });
    }
  };
  const SELECTED_ORDER_TYPE = 'selectOrderType';

  const orderTypeInCart = cart?.payments?.ptOrderType || allOrderKeys[0];

  const [selectedKey, setSelectedKey] = useState(orderTypeInCart);

  useEffect(() => {
    // calling add/update payment method on component mount
    if (ptPaymentMethod !== '' && selectedKey !== '') {
      updatePaymentMethod({
        paymentMethod: ptPaymentMethod,
        billingAddressId: billingAddresses[0].addressId,
        ptOrderType: selectedKey,
      });
    }
  }, []);

  return (
    <Formik onSubmit={updateSelectedOrderType} initialValues={{ key: selectedKey }}>
      {({ handleChange }) => {
        const billingAddressForSelectedCC = billingAddresses[0];
        return (
          <Form data-testid="credit-card-list__container_test-id" onChange={handleChange}>
            <SubmitOrderTypeOnChange />
            <div className={styles.container}>
              {allOrderKeys.map((key) => {
                return (
                  <div
                    key={key}
                    className={styles.cardContainer}
                    data-testid={`credit-card-list__container-${key}_test-id`}
                  >
                    <RadioButton
                      data-testid={`pt_order-type-list__${key}-radio-button_test-id`}
                      name={SELECTED_ORDER_TYPE}
                      id={`pt_order-type__${key}`}
                      value={key}
                      classes={{
                        icon: styles.radionIcon,
                        labelContent: styles.radioLabel,
                        label: styles.radioLabel,
                      }}
                      checked={key === selectedKey}
                    >
                      {allOrderTypes[key]}
                    </RadioButton>
                  </div>
                );
              })}
            </div>
            {billingAddressForSelectedCC && (
              <>
                <div className={styles.billingAddressTitle}>
                  {formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
                </div>
                <div className={styles.address}>
                  {getFormattedAddressinMultiline(billingAddressForSelectedCC, Boolean(isCSVFeatureFlagEnabled))}
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default OrderTypeList;
