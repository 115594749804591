import ContactUs, { Data } from 'ui/customer-support/components/contact-us';

type Props = {
  data: Data;
};

const ContactUsTastic = ({ data }: Props) => {
  return <ContactUs data={data} />;
};

export default ContactUsTastic;
