export const refinementRemovedEventName = 'refinementRemoved';
export const refinementsClearedEventName = 'refinementsCleared';

export enum ProductKeys {
  TYPE = 'productType',
  NAME = 'name',
}

export enum VariantKeys {
  PRICE_CHANNEL = 'variants.prices.channel',
  IS_ON_STOCK_FOR_CHANNEL = 'variants.availability.isOnStockForChannel',
}

import { FEATURE_FLAG_LIST } from 'hooks';
import { getFeatureFlag } from 'hooks/useFeatureFlags';
import { VariantAttributes_STATIC, VariantAttributes_RUNTIME } from 'shared/utils/constants';

type VariantAttributesType = typeof VariantAttributes_STATIC & typeof VariantAttributes_RUNTIME;

export const VariantAttributes: VariantAttributesType = new Proxy({} as VariantAttributesType, {
  get(_, prop: keyof typeof VariantAttributes_RUNTIME) {
    const selectedErrorCodes = getFeatureFlag(FEATURE_FLAG_LIST.ENABLE_COMMON_MODULES)
      ? VariantAttributes_RUNTIME
      : VariantAttributes_STATIC;
    return (selectedErrorCodes as Record<string, string>)[prop];
  },
});
