import React, { FC, useMemo } from 'react';
import { Icon } from '@lululemon/ecom-pattern-library';
import { NgcAddress } from 'shared/types/account/Address';
import { useCart } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { createPaymentType } from 'helpers/paymentTypes';
import { getFormattedAddressinMultiline } from 'helpers/utils/formatAddress';
import { maskValue } from 'helpers/utils/maskCreditcardValue';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import useResolveCCImage from 'hooks/useResolveCCImage';
import { useBusinessUnit } from 'frontastic';
import { CreditCardLabelType } from 'frontastic/hooks/useCybersource/types';
import { useAccount } from 'frontastic/index';
import styles from './orderReviewSummary.module.scss';
import OrderReviewSummaryUnit, { OrderSummaryItem } from './orderReviewSummaryUnit';
import { CheckoutStep } from '../../index';
import { formatShippingInfo } from '../../utils';

type OrderReviewSummaryProps = {
  activeStep: CheckoutStep;
  editOrderSummaryClickHandler: (stepToMove: CheckoutStep) => void;
};

const OrderReviewSummary: FC<OrderReviewSummaryProps> = ({ activeStep, editOrderSummaryClickHandler }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
  const { resolveCCImage } = useResolveCCImage();

  const { cart, ptPaymentMethod } = useCart();
  const PaymentType = createPaymentType(ptPaymentMethod);
  const { customInvoiceTerms } = useBusinessUnit();
  const { isFeatureEnabled } = useFeatureFlags();
  const { isProductTestingUser } = useAccount();
  const isCSVFeatureFlagEnabled = isFeatureEnabled(FEATURE_FLAG_LIST.ORDER_DETAILS_CSV);
  const isProductTestingEnabled = isProductTestingUser && isFeatureEnabled(FEATURE_FLAG_LIST.PRODUCT_TESTING);

  const paymentMethodAddedInCart = cart?.payments?.paymentMethod;

  const getPaymentContent = () => {
    if (paymentMethodAddedInCart === PaymentType.creditCard) {
      const cardNumber = cart?.payments?.cardNumber || '';
      const last4Digits = maskValue(cardNumber);
      const cardType = cart?.payments?.cardType || '';

      return cardNumber ? (
        <div className={styles.paymentDetails}>
          <Icon className={styles.icon} content={resolveCCImage(cardNumber)} />
          <div className={styles.subText}>
            {formatMessage({
              id: 'ending.in',
              values: {
                last4Digits,
                ccType: CreditCardLabelType[cardType as keyof typeof CreditCardLabelType],
              },
              defaultMessage: `{ccType} ending in ${last4Digits}`,
            })}
          </div>
        </div>
      ) : (
        ''
      );
    } else if (paymentMethodAddedInCart === PaymentType.productTesting) {
      const orderType = cart?.payments?.ptOrderType ? cart?.payments?.ptOrderType.replace(/_/g, ' ') : '';
      return <div className={styles.ptText}>{orderType}</div>;
    }

    if (paymentMethodAddedInCart === PaymentType.AR) {
      const billingAddress = cart?.payments?.billingAddress;

      return (
        <div className={styles.paymentDetails}>
          <div className={styles.arIcon}>{formatOrdersMessage({ id: 'ar.icon.title', defaultMessage: 'AR' })}</div>
          <div>
            <div className={styles.arTitle}>
              {formatOrdersMessage({
                id: 'ar',
                defaultMessage: 'Account Receivable',
              })}
              <span className={styles.netAR}>
                {formatOrdersMessage({
                  id: 'net',
                  defaultMessage: 'Net',
                })}
                {customInvoiceTerms}
              </span>
            </div>
            <div className={styles.address}>
              <div className={styles.billingAddressTitle}>
                {formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
              </div>
              {billingAddress ? getFormattedAddressinMultiline(billingAddress, Boolean(isCSVFeatureFlagEnabled)) : '-'}
            </div>
          </div>
        </div>
      );
    }

    return '';
  };

  const summaryItems = useMemo(() => {
    const shippingAddress = cart?.shippingAddress
      ? getFormattedAddressinMultiline(cart?.shippingAddress as NgcAddress, Boolean(isCSVFeatureFlagEnabled))
      : '';

    const freeText = formatMessage({ id: 'freeShipping', defaultMessage: 'FREE' });
    const shippingMethod = cart?.shippingInfo ? formatShippingInfo(cart?.shippingInfo, freeText) : '';

    const paymentStep: OrderSummaryItem = {
      stepToMove: CheckoutStep.PAYMENT,
      heading: formatMessage(
        isProductTestingEnabled
          ? {
              id: 'paying.for',
              defaultMessage: 'Paying for',
            }
          : {
              id: 'paying.with',
              defaultMessage: 'Paying with',
            },
      ),
      content: getPaymentContent(),
      name: 'payment',
    };

    const orderSummaryData: OrderSummaryItem[] = [
      {
        stepToMove: CheckoutStep.SHIPPING,
        heading: formatMessage({
          id: 'sending.to',
          defaultMessage: 'Sending to',
        }),
        content: shippingAddress,
        name: 'shippingAddress',
      },
      {
        stepToMove: CheckoutStep.SHIPPING,
        heading: formatMessage({
          id: 'estimated.delivery',
          defaultMessage: 'Estimated delivery',
        }),
        content: shippingMethod,
        name: 'shippingMethod',
      },
    ];

    if (cart?.poNumber) {
      orderSummaryData.push({
        stepToMove: CheckoutStep.SHIPPING,
        heading: formatMessage({
          id: 'order.reference.id',
          defaultMessage: 'Order reference ID',
        }),
        content: cart?.poNumber,
        name: 'poNumber',
      });
    }

    switch (activeStep) {
      case CheckoutStep.PAYMENT:
        return orderSummaryData;
      case CheckoutStep.REVIEW_SUMMARY:
        orderSummaryData.push(paymentStep);
        return orderSummaryData;
      default:
        return [];
    }
  }, [activeStep, cart?.poNumber, cart?.shippingAddress, cart?.shippingInfo]);

  return (
    <div data-testid="order-review-summary__container_test-id" className={styles.orderSummary}>
      {summaryItems.map((summaryItem, index) => (
        <OrderReviewSummaryUnit
          {...summaryItem}
          key={index}
          editOrderSummaryClickHandler={editOrderSummaryClickHandler}
        />
      ))}
    </div>
  );
};

export default OrderReviewSummary;
