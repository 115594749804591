export const TERMS_OF_SALE = 'terms-of-sale';
import { getFeatureFlag } from 'hooks/useFeatureFlags';
import { FEATURE_FLAG_LIST } from 'hooks/useFeatureFlags';
import { StatusCode_STATIC, StatusCode_RUNTIME } from 'shared/utils/constants';

type StatusCodeType = typeof StatusCode_STATIC & typeof StatusCode_RUNTIME;

export const StatusCode: StatusCodeType = new Proxy({} as StatusCodeType, {
  get(_, prop: keyof typeof StatusCode_RUNTIME) {
    const selectedErrorCodes = getFeatureFlag(FEATURE_FLAG_LIST.ENABLE_COMMON_MODULES)
      ? StatusCode_RUNTIME
      : StatusCode_STATIC;
    return (selectedErrorCodes as Record<string, number>)[prop];
  },
});

export const SHIPPING_METHOD_ACTION = 'setShippingMethod';
export const SHIPPING_METHOD_FOR_TAX_ACTION = 'calculateTax';

export const ProgramTypeLinks: { [key: string]: string } = {
  arena: 'arena',
  independent: 'independent',
  team: 'team-program',
  corporate: 'corporate',
  key_accounts: 'key-accounts',
  campus: 'campus',
};
