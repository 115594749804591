import { useState } from 'react';

export const useTransientToken = () => {
  const [isTokenResponseError, setIsTokenResponseError] = useState({
    securityCode: false,
    number: false,
  });

  const generateTransientToken = (
    microformObj: any,
    expiryMonth: string,
    expiryYear: string,
    callback: (token: string) => void,
  ) => {
    if (!microformObj) return;

    const options = { expirationMonth: expiryMonth, expirationYear: expiryYear };

    microformObj.createToken(options, (err: any, token: string) => {
      if (err) {
        setIsTokenResponseError({
          securityCode: Boolean(err.details?.find((e: any) => e.location === 'securityCode')),
          number: Boolean(err.details?.find((e: any) => e.location === 'number')),
        });
      } else {
        setIsTokenResponseError({ securityCode: false, number: false });
        callback(token);
      }
    });
  };

  return { generateTransientToken, isTokenResponseError };
};
