import { ReactNode } from 'react';
import { useConfirmLeave } from 'hooks';

export const ConfirmLeave = ({
  isConfirmDialogOpen,
  children,
}: {
  isConfirmDialogOpen: boolean;
  children: ReactNode;
}) => {
  useConfirmLeave(isConfirmDialogOpen);
  return <>{children}</>;
};
