export enum StaticPaymentType {
  creditCard = 'creditCard',
  AR = 'AR',
}

export const createPaymentType = (dynamicPaymentType: string) => {
  return {
    ...StaticPaymentType,
    ...{
      productTesting: dynamicPaymentType,
    },
  };
};

export type PaymentKey = keyof typeof createPaymentType;
export type PaymentValue = (typeof createPaymentType)[PaymentKey];
