import { Modal, Heading, Icon } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import { maskValue } from 'helpers/utils/maskCreditcardValue';
import useResolveCCImage from 'hooks/useResolveCCImage';
import { ModalCyberSourceRequestMapper } from 'frontastic/hooks/useCybersource/types';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import SaveOrCancel from '../../../components/save-or-cancel';

type Props = {
  modalProps: ModalCyberSourceRequestMapper;
  // closeEditModal: (modalProps: ModalCyberSourceRequestMapper) => void;
  closeConfirmationDeleteModal: (modalProps: ModalCyberSourceRequestMapper) => void;
  updateCreditCardWrapper: (modalProps: ModalCyberSourceRequestMapper, isRemove: boolean) => void;
};

const ConfirmationDeleteModal = ({ modalProps, closeConfirmationDeleteModal, updateCreditCardWrapper }: Props) => {
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const { resolveCCImage } = useResolveCCImage();
  const { isFeatureEnabled } = useFeatureFlags();
  const featureFlagDeleteSingleCardActive = isFeatureEnabled(FEATURE_FLAG_LIST.ALLOW_DELETE_ONLY_CREDIT_CARD);

  const closeModal = (modalProps: ModalCyberSourceRequestMapper) => {
    closeConfirmationDeleteModal(modalProps);
  };

  let showContent: boolean | undefined = false;
  if (featureFlagDeleteSingleCardActive) {
    showContent = modalProps?.isConfirmationModalOpen;
  } else {
    showContent = modalProps?.isConfirmationModalOpen && modalProps?.listCardsLength != 1;
  }

  return (
    <>
      <Modal
        aria-label="Delete Confirmation Modal" //change based on modal
        onRequestClose={() => {
          closeModal(modalProps);
        }}
        visible={modalProps.isConfirmationModalOpen}
        classes={{
          modal: 'min-w-[27rem]',
        }}
        data-testid="confirmation-message__modal_test-id"
      >
        {showContent && (
          <div className="w-min-423">
            <Heading className="lll-text-xsmall mb-16" tag="h1" data-testid="confirmation-message__heading_test-id">
              {formatPaymentMessage({
                id: 'remove.areyousure.edit_card',
                defaultMessage: 'Are you sure you want to remove this card?',
              })}
            </Heading>

            <div className="flex flex-col">
              <div className="mb-8 flex">
                <div className="mr-5 flex items-center">
                  <Icon
                    className="h-fit w-26"
                    title={modalProps?.creditCardDetails.cardType}
                    content={resolveCCImage(modalProps?.creditCardDetails.maskedPan)}
                  />
                </div>
                <div className="lll-text-body-2 ml-5 mr-16">
                  {formatPaymentMessage({
                    id: 'maskedCC.displayMsg',
                    defaultMessage: `ending in ${maskValue(modalProps?.creditCardDetails.maskedPan)}`,
                  })}
                </div>
              </div>
            </div>

            <div className="mt-16 flex items-center justify-between">
              <SaveOrCancel
                onCancel={() => closeModal(modalProps)}
                onSave={() => {
                  updateCreditCardWrapper(modalProps, true);
                }}
                variant="save"
                saveButtonText={formatPaymentMessage({
                  id: 'yes.remove.credit_card',
                  defaultMessage: 'YES REMOVE THIS CARD',
                })}
                displayCancelLink={true}
                displayCancelLinkText={formatPaymentMessage({
                  id: 'confirm.remove.keep.credit_card',
                  defaultMessage: 'No, I want to keep it',
                })}
                secondaryTestId="confirmation-message__cancel-link_test-id"
                testId="confirmation-message__confirm-button_test-id"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConfirmationDeleteModal;
