import { Link, successIcon, Icon } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './orderReviewSummaryUnit.module.scss';
import { CheckoutStep } from '../../../index';

export type OrderSummaryItem = {
  heading: string;
  content: string | React.JSX.Element;
  stepToMove: CheckoutStep;
  name?: string;
};

type Props = OrderSummaryItem & {
  editOrderSummaryClickHandler: (stepToMove: CheckoutStep) => void;
};

const OrderReviewSummaryUnit = ({ heading, content, stepToMove, editOrderSummaryClickHandler, name }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  return (
    <>
      <div className={styles.heading}>
        <Icon content={successIcon} />
        <div data-testid={`order-review-summary-unit__${name}-step-heading_test-id`}>{heading}</div>
      </div>
      <div data-testid={`order-review-summary-unit__${name}-step-content_test-id`} className={styles.content}>
        {content}
      </div>
      <div className={styles.changeStepButton}>
        <Link
          data-testid={`order-review-summary-unit__edit-${name}-button_test-id`}
          tag="button"
          variant="underline"
          onClick={() => {
            editOrderSummaryClickHandler(stepToMove);
          }}
        >
          {formatMessage({
            id: 'step.change.button',
            defaultMessage: 'Change',
          })}
        </Link>
      </div>
    </>
  );
};

export default OrderReviewSummaryUnit;
