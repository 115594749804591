import { parse } from 'url';
import { useMemo, ElementType } from 'react';
import Link from 'next/link';

type ConditionalLinkProps = HTMLCanvasElement & {
  href: string;
  component?: ElementType;
};

const getRelativeRoute = (href: string) => {
  if (href?.startsWith('#')) {
    return href;
  }

  try {
    const currentHost = window.location.hostname;
    const { pathname = '', search = '', host = '' } = parse(href);
    const relativeHref = `${pathname}${search || ''}`;
    const hostMatch = !host || host === currentHost;
    return hostMatch ? relativeHref : null;
  } catch (err) {
    return null;
  }
};

export const ConditionalLink = ({ href, component: Component = 'a', ...props }: ConditionalLinkProps) => {
  const relativeRoute = useMemo(() => getRelativeRoute(href), [href]);
  if (relativeRoute) {
    return (
      <Link href={relativeRoute}>
        <Component {...props} />
      </Link>
    );
  } else {
    return <Component href={href} {...props} />;
  }
};
