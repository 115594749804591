import { ProductCard } from 'shared/types/homePage';
import { Product } from 'shared/types/product';

/**
 * Transforms an array of `Product` objects into a structured list format for displaying products.
 * This function returns only the single default skuList item as an array
 *
 * @param {Product[]} products - Array of products to be transformed.
 * @param {string} type - Type of component
 * @param {string} id - Unique id of component
 * @returns {Object[]} - Array of formatted product collection objects, each including SKU details, pricing, and product information.
 */

export const toProductCollectionList = (products: Product[] | ProductCard[] | undefined, type: string, id: string) => {
  if (products && products.length > 0) {
    let item: any;
    const collectionList = products.map((product) => {
      if (product.variants && product.variants.length > 0) {
        item = {
          price: [
            {
              isSale: product.variants[0].attributes?.active ?? false,
              displaySale: product.variants[0].wholesalePrice ?? '',
              displayRegular: product.variants[0].retailPrice ?? '',
              saleWithoutTaxShipping: product.variants[0].wholesalePrice ?? '',
              regularWithoutTaxShipping: product.variants[0].retailPrice ?? '',
              taxOnly: '', // TODO: To be confirmed
            },
          ],
          sku: product.variants[0].sku,
          size: product.variants[0].attributes?.size ?? '',
          quantity: product.variants[0].attributes?.quantity ?? 1,
        };
      }

      const productPayload = {
        type: type,
        id: id,
        productList: [
          {
            skuList: [item],
            name: {
              unified: '', // This is something related with PCM. We can leave it as “N/A” for now.
              localized: product.name ?? '',
            },
            categoryUnifiedId: product.categories?.at(0)?.name ?? '',
            unifiedId: '', // This is something related with PCM. We can leave it as “N/A” for now.
            productId: product.productId ?? '',
          },
        ],
      };
      return productPayload;
    });

    return collectionList;
  } else {
    return {
      type: type,
      id: id,
      productList: [{}],
    };
  }
};

export const toPageProductDisplayedProduct = (
  products: Product[] | ProductCard[] | undefined,
  type: string,
  id: string,
) => {
  if (products && products.length > 0) {
    const productList: any = products.map((product) => {
      if (product.variants && product.variants.length > 0) {
        const item = {
          name: {
            unified: '', // This is something related with PCM. We can leave it as “N/A” for now.
            localized: product.name ?? '',
          },
          categoryUnifiedId: product.categories?.at(0)?.name ?? '',
          unifiedId: '', // This is something related with PCM. We can leave it as “N/A” for now.
          productId: product.productId ?? '',
          skuList: [
            {
              price: [
                {
                  isSale: product.variants[0].attributes?.active ?? false,
                  displaySale: product.variants[0].wholesalePrice ?? '',
                  displayRegular: product.variants[0].retailPrice ?? '',
                  saleWithoutTaxShipping: product.variants[0].wholesalePrice ?? '',
                  regularWithoutTaxShipping: product.variants[0].retailPrice ?? '',
                  taxOnly: '', // TODO: To be confirmed
                },
              ],
              sku: product.variants[0].sku,
              size: product.variants[0].attributes?.size ?? '',
              quantity: product.variants[0].attributes?.quantity ?? 1,
            },
          ],
        };
        return item;
      }
    });
    const productPayload = [
      {
        type: type,
        id: id,
        productList: productList,
      },
    ];
    return productPayload;
  } else {
    return [
      {
        type: type,
        id: id,
        productList: [{}],
      },
    ];
  }
};

/**
 * Transforms an array of `Product` objects into a structured list format for displaying products.
 * This function returns multiple added items in the cart as skuList
 *
 * @param {Product[]} products - Array of products to be transformed.
 * @param {string} type - Type of component
 * @param {string} id - Unique id of component
 * @returns {Object[]} - Array of formatted product collection objects, each including SKU details, pricing, and product information.
 */

export const toProductCartCollectionList = (
  products: Product[] | ProductCard[] | undefined,
  type: string,
  id: string,
) => {
  if (products && products.length > 0) {
    const collectionList = products.map((product) => {
      const productList = product.variants?.map((variant) => {
        const item = {
          price: [
            {
              isSale: variant.attributes?.active ?? false,
              displaySale: variant.wholesalePrice ?? '',
              displayRegular: variant.retailPrice ?? '',
              saleWithoutTaxShipping: variant.wholesalePrice ?? '',
              regularWithoutTaxShipping: variant.retailPrice ?? '',
              taxOnly: '', // TODO: To be confirmed
            },
          ],
          sku: variant.sku,
          size: variant.attributes?.size ?? '',
          quantity: variant.attributes?.quantity ?? 1,
        };
        return item;
      });

      const productPayload = {
        type: type,
        id: id,
        productList: [
          {
            skuList: productList,
            name: {
              unified: '', // This is something related with PCM. We can leave it as “N/A” for now.
              localized: product.name ?? '',
            },
            categoryUnifiedId: product.categories?.at(0)?.name ?? '',
            unifiedId: '', // This is something related with PCM. We can leave it as “N/A” for now.
            productId: product.productId ?? '',
          },
        ],
      };
      return productPayload;
    });

    return collectionList;
  } else {
    return {
      type: type,
      id: id,
      productList: [{}],
    };
  }
};
