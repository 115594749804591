import { useState, useEffect, useCallback } from 'react';
import useCybersource from 'frontastic/hooks/useCybersource';
import { ContextResponseMapper, RequestResponsePaymentSessionMapper } from 'frontastic/hooks/useCybersource/types';
import { NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import { PartnerIdUUID } from 'helpers/utils/partnerIdUUID';
import { getTokenData } from 'helpers/utils/requestHelper';

export const useTokenManagement = (businessUnitKey: string) => {
  const [token, setToken] = useState<string | null>(null);
  const [generatedPartnerUUID, setGeneratedPartnerUUID] = useState<string>('');
  const [captureContextError, setCaptureContextError] = useState<boolean>(false);
  const { generateCaptureContext } = useCybersource();

  const getContextToken = useCallback(
    async (businessUnitKey: string) => {
      const partnerUUIDValue = `${businessUnitKey}-${PartnerIdUUID()}`;
      setGeneratedPartnerUUID(partnerUUIDValue);

      try {
        const requestBody: RequestResponsePaymentSessionMapper = {
          data: [
            {
              type: 'sessions',
              attributes: {
                partnerIdUUID: partnerUUIDValue,
                targetOrigin: window.location.origin,
              },
            },
          ],
        };
        const result: ContextResponseMapper = await generateCaptureContext(requestBody);
        if (!result.isError) {
          const captureToken = result?.data.data[0]?.attributes.captureContext || null;
          setToken(captureToken);
          return result;
        } else {
          handleError(result.error);
          return null;
        }
      } catch (error) {
        handleError(error);
        return null;
      }
    },
    [generateCaptureContext],
  );

  const handleError = (error: any) => {
    if (NgcErrorCodes.GET_SESSIONS_ERROR === error?.code || error) {
      setCaptureContextError(true);
    }
  };

  useEffect(() => {
    if (businessUnitKey) {
      getContextToken(businessUnitKey);
    }
  }, [businessUnitKey, getContextToken]);

  const tokenData = token ? getTokenData(token) : null;

  return {
    token,
    generatedPartnerUUID,
    clientLibrary: tokenData?.clientLibrary,
    clientLibraryIntegrity: tokenData?.clientLibraryIntegrity,
    captureContextError,
  };
};
