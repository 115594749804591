import { useState } from 'react';
import { LoadingIndicator } from '@lululemon/ecom-pattern-library';
import classNames from 'classnames';
import { useAllSizeGuideEntriesByCategory } from 'hooks/contenful/useAllSizeGuideEntriesByCategory';
import MetaTitle from 'ui/meta-title';
import { GeneralSizeGuide } from './general-size-guide';
import styles from './size-guide.module.scss';
import { formatSizeGuidePageData } from './utils';

export const SizeGuide = ({}) => {
  const INITIAL_PARENT_CATEGORY_SLUG = 'womens';

  const [currentSlug, setCurrentSlug] = useState<string>(INITIAL_PARENT_CATEGORY_SLUG);
  const { data, isLoading } = useAllSizeGuideEntriesByCategory(currentSlug);

  const sizeGuideData = formatSizeGuidePageData(data);
  const metaDataTitleTag = sizeGuideData?.pageMetadata?.titleTag;
  const metaTitle = metaDataTitleTag?.split('|')[0];

  return (
    <>
      <MetaTitle title={metaTitle} description={sizeGuideData?.pageMetadata?.metaDescription} />
      <section
        className={classNames(styles.wrapper, styles.containerFluid, 'col-lg-6', 'lll-text-body-1', {
          [styles.loadingBox]: isLoading,
        })}
      >
        {isLoading || !sizeGuideData ? (
          <LoadingIndicator
            className={styles.loadingIndicator}
            color="red"
            data-testid="size-guide__loading-indicator_test-id"
          />
        ) : (
          <GeneralSizeGuide currentSlug={currentSlug} setCurrentSlug={setCurrentSlug} sizeGuideData={sizeGuideData} />
        )}
      </section>
    </>
  );
};
