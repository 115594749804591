import Spacer, { SpacerProps } from 'ui/spacer';

export type SpacerTasticProps = {
  data: SpacerProps;
};

const SpacerTastic = ({ data }: SpacerTasticProps) => {
  return <Spacer {...data} />;
};

export default SpacerTastic;
