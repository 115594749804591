import { Heading, Link } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { CUSTOMER_SUPPORT } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import styles from './contact-us.module.scss';

export type Data = {
  contactUsHeading: string;
  contactUsSubtextLevel1: string;
  contactUsSubtextLevel2: string;
  contactUsEmail: string;
};

type Props = {
  data: Data;
};

const ContactUs = ({ data }: Props) => {
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  const { contactUsHeading, contactUsSubtextLevel1, contactUsSubtextLevel2, contactUsEmail } = data;

  return (
    <div className={styles.container}>
      <div className={cs('lll-grid lll-grid-padding', styles.gridContainer)}>
        <Heading data-testid="customer-support__contact-us-heading_test-id" wrapperClassName={styles.heading} tag="h1">
          {contactUsHeading}
        </Heading>
        <div className={styles.subheadingContainer}>
          <div data-testid="customer-support__contact-us-subheading_test-id" className={styles.subheading}>
            <p className="lll-text-body-1" data-testid="customer-support__contact-us-subheading-1_test-id">
              {contactUsSubtextLevel1}
            </p>
            <p className="lll-text-body-1" data-testid="customer-support__contact-us-subheading-2_test-id">
              {contactUsSubtextLevel2}
            </p>
          </div>
          <Link
            href={`mailto:${contactUsEmail}`}
            onClick={() => {
              trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CUSTOMER_SUPPORT.CONTACT_US_EMAIL_LINK_CLICKED);
            }}
            variant="underline"
            data-testid="customer-support__contact-us-email_test-id"
            textStyle="body-1"
          >
            {contactUsEmail}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
