export const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const futureYearsCount = 10;
  const yearsOptions = Array.from({ length: futureYearsCount }, (_, index) => {
    const year = currentYear + index;
    return { label: year.toString().slice(-2), value: year.toString() };
  });
  return yearsOptions;
};

export const getCurrentMonth = () => {
  const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
  return month;
};

export const getCurrentYear = () => {
  return new Date().getFullYear().toString();
};

export const getMonthOptions = () => {
  const monthsOptions = Array.from({ length: 12 }, (_, index) => {
    const month = (index + 1).toString().padStart(2, '0'); // Ensure double-digit format
    return { label: month, value: month };
  });

  return monthsOptions;
};
