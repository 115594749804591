export enum CreditCardLabelType {
  masterCard = 'Mastercard',
  visa = 'Visa',
  discover = 'Discover',
  jcb = 'JCB',
  americanexpress = 'Amex',
}

export type CreditCardType = {
  partnerIdUUID: string;
  expires: string;
  cardToken: string;
  maskedPan: string;
  cardType: CreditCardLabelType | '';
  cardBillingAddressId: string;
  nameOnCard: string;
  isCardPrimary: boolean;
  isExpired?: boolean;
};

export type RequestCyberSourceDataMapper = {
  container?: string;
  key?: string | '';
  value: CreditCardType[];
};

export type ModalCyberSourceRequestMapper = {
  isModalOpen: boolean;
  isConfirmationModalOpen?: boolean;
  iswarningMessageModalOpen?: boolean;
  isWarningMessageModalOpen?: boolean;
  creditCardDetails: CreditCardType;
  listCardsLength: number;
};

export type DataPaymentSessionMapper = {
  type?: string;
  attributes: AttributesPaymentSessionMapper;
};

export type AttributesPaymentSessionMapper = {
  partnerIdUUID: string;
  targetOrigin?: string;
  captureContext?: string;
};

export type RequestResponsePaymentSessionMapper = {
  data: DataPaymentSessionMapper[];
};

export type AttributesSaveTokenMapper = {
  partnerIdUUID: string;
  jwttoken: string;
  cardHolderName: string;
  cardBillingAddressId: string;
  isCardPrimary: boolean;
  email: string;
};

export type RequestParamsSaveToken = {
  type?: string;
  attributes: AttributesSaveTokenMapper;
};

export type RequestSaveTokenMapper = {
  data: RequestParamsSaveToken[];
};

export type PaymentDetailsResponse = {
  isError: boolean;
  data: RequestCyberSourceDataMapper;
  error?: any;
};

export type ContextResponseMapper = {
  isError: boolean;
  data: RequestResponsePaymentSessionMapper;
  error?: any;
};
