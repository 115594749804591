// import MarketButton from 'ui/market-button/market-button';
import styles from './top-nav-desktop-bar.module.scss';
import ShippingLocation from '../shipping-location';

export const TopNavDesktopBar = () => (
  <div className={styles.topNavDesktopBar}>
    <ShippingLocation />
    {/* <MarketButton /> */}
  </div>
);
