import { ReactNode, createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useI18n from 'helpers/hooks/useI18n';
import { CDP_ANALYTIC_EVENT } from 'helpers/utils/googleAnalyticsEvents';
import { getGender } from 'helpers/utils/navHelper';
import { useAnalytics } from 'hooks/useAnalytics';
import { useAccount } from 'frontastic/index';
import { refinementRemovedEventName, refinementsClearedEventName } from './constants';
import { ActiveRefinement, ProductListContextShape, RefinementRemovedEvent, Sort, UiState } from './types';
import { BooleanFacet, FacetConfiguration, PriceConfiguration, RangeFacet, TermFacet } from '../types';

export const ProductListContext = createContext<ProductListContextShape>({
  facetsConfiguration: {},
  pricesConfiguration: {},
  totalItems: 0,
  activeRefinements: [],
  updateUiState() {},
  updateFacetsConfiguration() {},
  updatePricesConfiguration() {},
  refine() {},
  refineRange() {},
  replaceSort() {},
  removeAllRefinements() {},
  loadMore() {},
  uiState: { totalItems: 0 },
  limitStep: 12,
});

type Props = {
  children: ReactNode;
};

const ProductListProvider = ({ children }: Props) => {
  const router = useRouter();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  const { account } = useAccount();
  const { currency } = useI18n();

  const [uiState, setUiState] = useState<UiState>({ totalItems: 0, isLoading: true });

  const [facetsConfiguration, setFacetsConfiguration] = useState<Record<string, FacetConfiguration>>({});
  const [prevFacetsConfiguration, setPrevFacetsConfiguration] = useState<Record<string, FacetConfiguration>>();
  const cursor = Array.isArray(router.query.cursor) ? router.query.cursor[0] : router.query.cursor;
  const [pricesConfiguration, setPricesConfiguration] = useState<Record<string, PriceConfiguration>>({});
  const offsetMatch = cursor?.match(/(?<=offset:).+/);
  const { primaryInventoryChannelId, priceChannelId } = account?.companyProfile || {};
  const activeSort = useMemo<Sort | undefined>(() => {
    for (const q in router.query) {
      const match = q.match(/sortAttributes\[0\]\[(.+)\]/);

      if (match?.[1]) return { attribute: match[1], value: router.query[q] as 'asc' | 'desc' };
    }
  }, [router.query]);

  const limitStep = 12;

  const activeLimit = useMemo<number>(() => {
    return router.query.limit ? +router.query.limit : limitStep;
  }, [router.query, limitStep]);

  useEffect(() => {
    if (!uiState.totalItems && prevFacetsConfiguration) {
      setFacetsConfiguration(prevFacetsConfiguration);
    }
  }, [facetsConfiguration, prevFacetsConfiguration, uiState.totalItems]);

  const applyRefinements = useCallback(
    (
      facetsConfiguration: Record<string, FacetConfiguration>,
      sort?: Sort,
      limit?: number,
      scrollToTheTop = true,
      cursor = 0,
    ) => {
      const params = new URLSearchParams();
      !limit && setUiState((prevState: UiState) => ({ ...prevState, isLoading: true }));
      if (uiState?.searchQuery) params.set('q', uiState.searchQuery);

      const gender = getGender();
      if (gender) {
        params.set('gender', gender);
      }

      Object.values(facetsConfiguration).forEach((configuration) => {
        if (!configuration.selected) return;

        if (configuration.type === 'range') {
          params.set(`facets[${configuration.key}][min]`, (configuration.minSelected ?? configuration.min).toString());
          params.set(`facets[${configuration.key}][max]`, (configuration.maxSelected ?? configuration.max).toString());
        } else if (configuration.type === 'term' || configuration.type === 'color') {
          if (configuration.key === 'categories.categoryId') {
            configuration.terms
              .filter((term) => term.selected)
              .forEach((term) => params.append('categories[]', term.key));
          } else {
            configuration.terms
              .filter((term) => term.selected)
              .forEach((term, index) => params.set(`facets[${configuration.key}][terms][${index}]`, term.key));
          }
        } else if (configuration.type === 'boolean') {
          configuration.terms
            .filter((term) => term.selected)
            .forEach((term) => params.set(`facets[${configuration.key}][boolean]`, term.key));
        }
      });
      limit ? params.set('limit', limit.toString()) : params.set('limit', limitStep.toString());
      if (sort) params.set(`sortAttributes[0][${sort.attribute}]`, sort.value);
      const activeOffset = Array.isArray(router.query.cursor) ? router.query.cursor[0] : router.query.cursor;
      cursor > 0 && activeOffset !== 'offset:0' && params.set('cursor', `offset:${cursor}`);
      if (primaryInventoryChannelId && priceChannelId) {
        params.set('pic', primaryInventoryChannelId);
        params.set('prc', priceChannelId);
      }
      router.replace({ pathname: router.asPath.split('?')[0], query: params.toString() }, undefined, {
        scroll: scrollToTheTop,
      });
    },
    [uiState?.searchQuery, router],
  );

  const activeRefinements = useMemo(() => {
    const refinements = [] as ActiveRefinement[];

    const newFacetsConfiguration = structuredClone(facetsConfiguration);

    const addRefinement = (configuration: FacetConfiguration, label: string, refineCb: () => void) => {
      refinements.push({
        attribute: configuration.key,
        label,
        refine() {
          setUiState((prevUiState) => ({ ...prevUiState, allActiveProducts: [] }));
          // Not required as part of GA Analysis
          // trackEvent(
          //   EVENT_CATEGORY.FILTER_INTERACTION,
          //   CDP_ANALYTIC_EVENT.FILTER_PILL_CLICKED(configuration.key, label),
          // );
          refineCb();

          applyRefinements(newFacetsConfiguration, activeSort, activeLimit);

          window.dispatchEvent(
            new CustomEvent<RefinementRemovedEvent>(refinementRemovedEventName, {
              detail: { attribute: configuration.key },
            }),
          );
        },
      });
    };

    Object.values(newFacetsConfiguration)
      .filter((configuration) => configuration.selected)
      .forEach((configuration) => {
        if (configuration.type === 'range') {
          addRefinement(
            configuration,
            `${CurrencyHelpers.formatForCurrency(
              configuration.minSelected ?? configuration.min,
              router?.locale,
              currency,
            )} - ${CurrencyHelpers.formatForCurrency(
              configuration.maxSelected ?? configuration.max,
              router?.locale,
              currency,
            )}`,
            () => {
              configuration.selected = false;
            },
          );
        } else if (
          configuration.type === 'term' ||
          configuration.type === 'color' ||
          configuration.type === 'boolean'
        ) {
          configuration.terms
            .filter((t) => t.selected)
            .forEach((term) => {
              addRefinement(configuration, term.label, () => {
                term.selected = false;
                configuration.selected = configuration.terms.some((t) => t.selected);
              });
            });
        }
      });

    return refinements;
  }, [
    currency,
    facetsConfiguration,
    router?.locale,
    applyRefinements,
    activeSort,
    activeLimit,
    trackEvent,
    EVENT_CATEGORY,
  ]);

  const replaceSort = useCallback(
    (newSort: Sort) => {
      setUiState((prevState: UiState) => ({ ...prevState, isLoading: true }));
      //Resetting cache on sorting
      setUiState((prevUiState) => ({ ...prevUiState, allActiveProducts: [] }));
      const cursor = 0;
      applyRefinements(facetsConfiguration, newSort, activeLimit, cursor);
    },
    [facetsConfiguration, applyRefinements, activeLimit, trackEvent, EVENT_CATEGORY],
  );

  const refine = useCallback(
    (attribute: string, key: string) => {
      setUiState((prevUiState) => ({ ...prevUiState, allActiveProducts: [] }));
      const newFacetsConfiguration = structuredClone(facetsConfiguration);

      const facet = newFacetsConfiguration[attribute] as TermFacet | BooleanFacet;

      const term = facet.terms.find((t) => t.key === key);

      if (term) {
        term.selected = !term.selected;

        if (facet.type === 'boolean')
          facet.terms.filter((t) => t.key !== term.key).forEach((term) => (term.selected = false));
      }

      facet.selected = facet.terms.some((t) => t.selected);

      setPrevFacetsConfiguration(newFacetsConfiguration);
      applyRefinements(newFacetsConfiguration, activeSort);
    },
    [facetsConfiguration, applyRefinements, activeSort, trackEvent, EVENT_CATEGORY],
  );

  const refineRange = useCallback(
    (attribute: string, value: [number, number]) => {
      const newFacetsConfiguration = structuredClone(facetsConfiguration);

      const facet = newFacetsConfiguration[attribute] as RangeFacet;

      if (facet) {
        facet.minSelected = value[0];
        facet.maxSelected = value[1];
        facet.selected = true;
      }

      applyRefinements(newFacetsConfiguration, activeSort);
    },
    [facetsConfiguration, applyRefinements, activeSort],
  );

  const loadMore = useCallback(() => {
    const offset = offsetMatch?.length ? +offsetMatch[0] + limitStep : limitStep;
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CDP_ANALYTIC_EVENT.LOAD_MORE_BUTTON_CLICK);
    applyRefinements(facetsConfiguration, activeSort, limitStep, false, offset);
  }, [facetsConfiguration, activeSort, applyRefinements, activeLimit, limitStep, trackEvent, EVENT_CATEGORY]);

  const removeAllRefinements = useCallback(() => {
    //Resetting cache
    setUiState((prevUiState) => ({ ...prevUiState, allActiveProducts: [] }));
    // Disable this as per requirement as per GA analysis
    // trackEvent(EVENT_CATEGORY.FILTER_INTERACTION, CDP_ANALYTIC_EVENT.CLEAR_FILTERS_CLICKED);
    const newFacetsConfiguration = structuredClone(facetsConfiguration);

    Object.values(newFacetsConfiguration).forEach((configuration) => {
      configuration.selected = false;

      if (configuration.type === 'term' || configuration.type === 'color')
        configuration.terms.forEach((t) => (t.selected = false));
    });

    applyRefinements(newFacetsConfiguration, activeSort);

    window.dispatchEvent(new CustomEvent(refinementsClearedEventName));
  }, [applyRefinements, facetsConfiguration, activeSort, trackEvent, EVENT_CATEGORY]);

  const value = useMemo(
    () => ({
      ...uiState,
      facetsConfiguration,
      pricesConfiguration,
      activeSort,
      activeLimit,
      activeRefinements,
      updateUiState: setUiState,
      updateFacetsConfiguration: setFacetsConfiguration,
      updatePricesConfiguration: setPricesConfiguration,
      refine,
      refineRange,
      replaceSort,
      removeAllRefinements,
      loadMore,
      uiState,
      limitStep,
    }),
    [
      uiState,
      facetsConfiguration,
      pricesConfiguration,
      activeSort,
      activeLimit,
      activeRefinements,
      setUiState,
      setFacetsConfiguration,
      setPricesConfiguration,
      refine,
      refineRange,
      replaceSort,
      removeAllRefinements,
      loadMore,
      uiState,
      limitStep,
    ],
  );

  return <ProductListContext.Provider value={value}>{children}</ProductListContext.Provider>;
};

export default ProductListProvider;

export const useProductList = () => useContext(ProductListContext);
