import { useState, Dispatch, SetStateAction, FC } from 'react';
import { useRouter } from 'next/router';
import sortBy from 'lodash/sortBy';
import { NgcErrorType } from 'shared/types/error';
import { ShippingMethod } from 'shared/types/ngcCart';
import { useCart } from 'context';
import { parseNGCError } from 'helpers/ngc';
import { NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import OrderReference from './order-reference';
import ShippingAddress from './shipping-address';
import ShippingMethods from './shipping-methods';
import styles from './shipping-wrapper.module.scss';
import { CheckoutError, ShippingMethodError } from '../../index';
import { StatusCode } from 'ui/checkout/utils/constant';
import { SHIPPING_METHOD_ACTION } from '../../utils/constant';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';

type ShippingWrapperProps = {
  poNumber: string;
  setPoNumber: Dispatch<SetStateAction<string>>;
  poNumberError: CheckoutError;
  loadingShippingMethod: boolean;
  setLoadingShippingMethod: (value: boolean) => void;
};

export const sortData = (data: ShippingMethod[]) => {
  return sortBy(data, (shippingMethod) => shippingMethod.shippingPrice?.total);
};

const ShippingWrapper: FC<ShippingWrapperProps> = ({
  poNumber,
  setPoNumber,
  poNumberError,
  loadingShippingMethod,
  setLoadingShippingMethod,
}) => {
  const initialError = {
    isError: false,
    callBack: null,
    showTryAgain: false,
    errorType: null,
  };
  const router = useRouter();
  const {
    cart,
    getShippingMethods,
    getShippingMethodsWithAction,
    addShippingAddress,
    updateShippingAddress,
    updateShippingMethod,
  } = useCart();
  const [shippingAddressError, setShippingAddressError] = useState<CheckoutError>(initialError);
  const [shippingMethodError, setShippingMethodError] = useState<ShippingMethodError>(initialError);

  const [shippingMethodsListData, setShippingMethodsListData] = useState<ShippingMethod[]>();

  const [loadingShippingAddress, setLoadingShippingAddress] = useState(false);

  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingMethod>();
  const [apiFailureMethod, setApiFailureMethod] = useState<ShippingMethod>();
  const [processingShippingMethod, setProcessingShippingMethod] = useState<ShippingMethod>();

  const { isFeatureEnabled } = useFeatureFlags();
  const isFeatureFlagUpdateShippingMethodActive = isFeatureEnabled(
    FEATURE_FLAG_LIST.OPTIMIZATION_UPDATE_SHIPPING_METHOD,
  );
  const isSplitNgcCallsGetShippingMethodsFlag = isFeatureEnabled(
    FEATURE_FLAG_LIST.IS_SPLIT_NGC_CALLS_GETSHIPPINGMETHIDS_FLAG,
  );
  const isFeatureFlagShippingAddressActive = isFeatureEnabled(FEATURE_FLAG_LIST.IS_EAOELF782_ACTIVE);

  const handleFetchShippingMethodsError = (error: NgcErrorType, callback: () => void) => {
    setLoadingShippingMethod(false);
    // Only navigate to the below url if cart already ordered
    // for the rest of the error codes, just set shipping method error
    if (error?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
      router.push('/something?isCartAlreadyOrdered=true');
    }
    setShippingMethodError({
      isError: true,
      callBack: () => {
        if (callback) {
          callback();
        }
      },
      showTryAgain: true,
      errorType: 'getShippingMethod',
    });
  };

  const fetchShippingMethods = async () => {
    setLoadingShippingMethod(true);
    setShippingMethodError(initialError);
    if (isSplitNgcCallsGetShippingMethodsFlag) {
      try {
        const shippingSetMethodsResponse = await getShippingMethodsWithAction('setShippingRateInput');
        if (shippingSetMethodsResponse) {
          const shippingMethodsResponse = await getShippingMethodsWithAction('getShippingMethods');
          const sortedData = sortData(shippingMethodsResponse);
          setShippingMethodsListData(sortedData);
          setLoadingShippingMethod(false);
        }
      } catch (error) {
        const parsedError = parseNGCError(error);
        handleFetchShippingMethodsError(parsedError, () => fetchShippingMethods());
      }
    } else {
      try {
        const shippingMethodsResponse = await getShippingMethods();
        const sortedData = sortData(shippingMethodsResponse);
        setShippingMethodsListData(sortedData);
        setLoadingShippingMethod(false);
      } catch (error) {
        const parsedError = parseNGCError(error);
        handleFetchShippingMethodsError(parsedError, () => fetchShippingMethods());
      }
    }
  };

  const handleUpdateShippingMethodErrorLegacy = (callback: () => void) => {
    setLoadingShippingMethod(false);
    setShippingMethodError({
      isError: true,
      callBack: () => {
        if (callback) {
          callback();
        }
      },
      showTryAgain: true,
      errorType: 'updateShippingMethod',
    });
  };

  const updateShippingMethodToCartWrapperLegacy = async (shippingMethod: ShippingMethod, retry = false) => {
    setLoadingShippingMethod(true);
    setShippingMethodError(initialError);
    if (shippingMethod !== undefined) {
      const shippingMethodId = shippingMethod.id as string;
      const apiFailureMethodId = apiFailureMethod?.id as string;

      try {
        const updatedCart = await updateShippingMethod(retry ? apiFailureMethodId : shippingMethodId, retry, '');
        setSelectedShippingMethod(shippingMethod);
        setLoadingShippingMethod(false);
        return updatedCart;
      } catch (error) {
        const parsedError = parseNGCError(error);
        if (parsedError?.status === StatusCode.TIMEOUT) {
          const updatedCart = await updateShippingMethodToCartWrapperLegacy(shippingMethod, true);
          if (updatedCart && cart) {
            const isMethodUpdated =
              updatedCart?.shippingInfo?.id === shippingMethod.id &&
              updatedCart.cartTotal?.taxStatus === 'S' &&
              updatedCart.cartVersion > cart?.cartVersion &&
              new Date(updatedCart.lastModifiedAt).getTime() > new Date(cart?.lastModifiedAt).getTime();
            if (isMethodUpdated) {
              setSelectedShippingMethod(shippingMethod);
              setLoadingShippingMethod(false);
            } else {
              setApiFailureMethod(shippingMethod);
              handleUpdateShippingMethodErrorLegacy(() =>
                updateShippingMethodToCartWrapperLegacy(shippingMethod, false),
              );
            }
          }
        } else {
          // This is the case when update shipping still fails but it's not timeout
          // We handle it the same way
          setApiFailureMethod(shippingMethod);
          handleUpdateShippingMethodErrorLegacy(() => updateShippingMethodToCartWrapperLegacy(shippingMethod, false));
        }
      }
    }
  };

  const handleUpdateShippingMethodError = () => {
    setLoadingShippingMethod(false);
    setShippingMethodError({
      isError: true,
      callBack: () => processingShippingMethod && updateShippingMethodToCartWrapper(processingShippingMethod),
      showTryAgain: true,
      errorType: 'updateShippingMethod',
    });
  };

  const checkShippingMethodUpdatedWithLatestCart = async (shippingMethod: ShippingMethod) => {
    try {
      const shippingMethodId = shippingMethod.id;
      const updatedCart = await updateShippingMethod(shippingMethodId, true, SHIPPING_METHOD_ACTION);
      if (updatedCart && cart) {
        const isMethodUpdated = updatedCart?.shippingInfo?.id === shippingMethodId;
        if (isMethodUpdated) {
          setSelectedShippingMethod(shippingMethod);
          setLoadingShippingMethod(false);
        } else {
          handleUpdateShippingMethodError();
        }
      }
    } catch (error) {
      handleUpdateShippingMethodError();
    }
  };

  const updateShippingMethodToCartWrapper = async (shippingMethod: ShippingMethod) => {
    setLoadingShippingMethod(true);
    setShippingMethodError(initialError);
    setProcessingShippingMethod(shippingMethod);

    if (!shippingMethod) {
      setLoadingShippingMethod(false);
      return;
    }

    try {
      const shippingMethodId = shippingMethod.id;
      const shippingKey = shippingMethod?.key || '';
      const updatedCart = await updateShippingMethod(shippingMethodId, false, SHIPPING_METHOD_ACTION, shippingKey);
      setSelectedShippingMethod(shippingMethod);
      setLoadingShippingMethod(false);
      return updatedCart;
    } catch (error) {
      const parsedError = parseNGCError(error);
      if (parsedError?.status === StatusCode.TIMEOUT) {
        checkShippingMethodUpdatedWithLatestCart(shippingMethod);
      } else {
        handleUpdateShippingMethodError();
      }
    }
  };

  const updateShippingAddressErrorHandler = (addressId: string, error: NgcErrorType) => {
    setLoadingShippingAddress(false);
    let showTryAgain = false;
    if (
      error?.code === NgcErrorCodes.CART_NOT_FOUND ||
      error?.code === NgcErrorCodes.OPERATION_NOT_PERMITTED ||
      error?.code === NgcErrorCodes.SHIPPING_ADDRESS_NULL
    ) {
      showTryAgain = true;
    } else if (error?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
      router.push('/something?isCartAlreadyOrdered=true');
    }
    setShippingAddressError({
      isError: true,
      callBack: () => {
        updateShippingAddressHandler(addressId, true);
      },
      showTryAgain,
    });
  };

  const updateShippingAddressHandler = async (addressId: string, isRetry = false, initialLoad = false) => {
    setLoadingShippingAddress(true);
    setShippingAddressError(initialError);
    if (initialLoad) {
      setShippingAddressOnInitialRender();
      return;
    }

    try {
      if (isFeatureFlagShippingAddressActive && cart?.shippingAddress?.addressId) {
        await updateShippingAddress(addressId, isRetry);
      } else if (isFeatureFlagShippingAddressActive) {
        await addShippingAddress(addressId);
      } else {
        await updateShippingAddress(addressId, isRetry);
      }
      setShippingAddressOnInitialRender();
    } catch (error) {
      const parsedError = parseNGCError(error);
      updateShippingAddressErrorHandler(addressId, parsedError);
    }
  };

  const setShippingAddressOnInitialRender = () => {
    setLoadingShippingAddress(false);
    fetchShippingMethods();
  };

  // until feature flag info not availble, we will delay the rendering of main content, so that based on feature flag value proper rendering and API calls can happen
  if (isSplitNgcCallsGetShippingMethodsFlag === undefined) return null;

  return (
    <div className={styles.shippingWrapperContainer}>
      <div data-testid="shipping-information__address_test-id">
        <ShippingAddress
          onChangeHandler={updateShippingAddressHandler}
          isLoading={loadingShippingAddress}
          shippingAddressError={shippingAddressError}
          setShippingAddressError={setShippingAddressError}
        />
      </div>

      <div data-testid="shipping-information__methods_test-id">
        <ShippingMethods
          fetchShippingMethods={fetchShippingMethods}
          onChangeHandler={
            isFeatureFlagUpdateShippingMethodActive
              ? updateShippingMethodToCartWrapper
              : updateShippingMethodToCartWrapperLegacy
          }
          shippingMethodsListData={shippingMethodsListData || []}
          isLoading={loadingShippingMethod}
          selectedShippingMethod={selectedShippingMethod as ShippingMethod}
          setSelectedShippingMethod={setSelectedShippingMethod}
          shippingMethodError={shippingMethodError}
        />
      </div>
      <OrderReference setPoNumber={setPoNumber} poNumber={poNumber} poNumberError={poNumberError} />
    </div>
  );
};

export default ShippingWrapper;
