import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '@lululemon/ecom-pattern-library';
import { Account } from 'shared/types/account';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { getPath } from '.';
import styles from './main-details.module.scss';

export interface MainDetailsCategoryListProps {
  account: Account | undefined;
  limit: number;
  categoryPath: string;
  categoryName: string;
  gender: string;
  innerRef: any;
  handleCloseDetailedNav: () => void;
}

export const MainDetailsCategoryList = ({
  account,
  limit,
  categoryPath,
  categoryName,
  gender,
  innerRef,
  handleCloseDetailedNav,
}: MainDetailsCategoryListProps) => {
  const router = useRouter();
  const path = getPath(account, limit, { gender, path: categoryPath });
  //Google Analytics

  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const handleOnClick = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.HEADER_SUB_NAV_CLICK(categoryName));
    handleCloseDetailedNav();
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.HEADER_SUB_NAV_CLICK(categoryName));
      e.preventDefault();
      handleCloseDetailedNav();
      router.push(path);
    }
  };

  return (
    <Link
      tag="div"
      className={styles.mainDetailsCategory}
      variant="arrowBold"
      direction="right"
      onClick={handleOnClick}
      onKeyDown={onKeyDown}
    >
      <div ref={innerRef} tabIndex={-1}>
        <NextLink href={path}>{categoryName}</NextLink>
      </div>
    </Link>
  );
};
