import { ChangeEvent, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { AccordionStatelessV2, AccordionItemV2, Field } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { ExpandedAccordionPanels } from 'shared/types/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './orderReference.module.scss';
import { CheckoutError } from '../../../index';
import CheckoutNotification from '../../checkoutNotification';

export type Props = {
  poNumber: string;
  setPoNumber: Dispatch<SetStateAction<string>>;
  poNumberError: CheckoutError;
};

const OrderReference = ({ poNumber, setPoNumber, poNumberError }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const [showRefIdError, setShowRefIdError] = useState(false);
  const [expandedAccordionPanels, setExpandedAccordionPanels] = useState<ExpandedAccordionPanels>({
    0: Boolean(poNumber),
  });

  const handleBlur = () => {
    if (poNumber.length > 25) {
      setShowRefIdError(true);
    } else {
      setShowRefIdError(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value || '';
    setPoNumber(value);
    if (value.length > 25) {
      setShowRefIdError(true);
    } else {
      setShowRefIdError(false);
    }
  };

  const errorText = formatMessage({
    defaultMessage: 'Order reference ID can’t exceed 25 characters.',
    id: 'order.reference.error',
  });

  const getErrorMessage = useCallback(() => {
    const errorText = formatMessage({
      defaultMessage: 'We were unable to load your PO Number',
      id: 'poNumber.error',
    });

    return <span>{errorText}</span>;
  }, []);

  return (
    <AccordionStatelessV2
      className={styles.orderReferenceContainer}
      data-testid="order-reference__accordion_test-id"
      defaultTypeStyle="xsmall"
      expandedAccordionPanels={expandedAccordionPanels}
      id="order-reference__accordion-id"
      onClickAccordionItem={(index: number) => {
        setExpandedAccordionPanels((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
      }}
      v2
    >
      <AccordionItemV2
        heading={
          <div data-testid="accordion__heading_test-id" className="lll-text-xsmall">
            {formatMessage({ id: 'order.reference.id', defaultMessage: 'Order reference ID' })}
            <span className={cx(styles.optional, 'lll-text-body-1')}>
              ({formatMessage({ id: 'optional.txt', defaultMessage: 'optional' })})
            </span>
          </div>
        }
        className={styles.accordianItem}
      >
        {poNumberError.isError && (
          <CheckoutNotification
            message={getErrorMessage()}
            show={poNumberError.isError}
            showTryAgain={poNumberError.showTryAgain}
            tryAgainCallback={poNumberError.callBack}
          />
        )}
        <div className={cx(styles.description, 'lll-text-body-2')} data-testid="order-reference__label_test-id">
          {formatMessage({
            defaultMessage:
              'You can add a name that makes it easy for you to manage your orders such as a PO number. This reference ID will appear on your invoice and packing slip.',
            id: 'order.reference.text',
          })}
        </div>
        <Field
          className={styles.inputField}
          data-testid="order-reference__input_test-id"
          hint={showRefIdError ? errorText : undefined}
          id="order-reference-id__input"
          name="order-reference-id"
          onBlur={handleBlur}
          onChange={handleChange}
          status={showRefIdError ? 'error' : undefined}
          value={poNumber}
        >
          {formatMessage({ defaultMessage: 'Order reference ID', id: 'order.reference.id' })}
        </Field>
      </AccordionItemV2>
    </AccordionStatelessV2>
  );
};

export default OrderReference;
