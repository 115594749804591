import { ComponentType } from 'react';
import dynamic from 'next/dynamic';
import RegionSelectorBarTastic from './bar/region-selector';
import ContactUsTastic from './contact-us';
import DetailedFooterTastic from './detailed-footer';
import FaqTastic from './faq';
import CategoryPromotionCarouselTastic from './home/category-promotion-carousel';
import QuickLinkGroupTastic from './home/quick-links-group';
import WhatsNewCardGroupTastic from './home/whats-new-card-group';
import LegalPageTastic from './legal-page';
import LuluHeaderTastic from './lulu-header';
import LuluHeaderNavTastic from './lulu-header-nav';
import Missing from './missing';
import NotFoundTastic from './not-found';
import ProductPromotionCarouselTastic from './pattern-library/product-promotion-carousel';
import SimpleFooterTastic from './simple-footer';
import SimpleHeaderTastic from './simple-header';
import SizeGuideTastic from './size-guide';
import SpacerTastic from './spacer';
import UnderMaintenanceTastic from './under-maintenance';

const Markdown = dynamic(() => import('./markdown'));
const AccountDetails = dynamic(() => import('./account/details'));
const AccountLogin = dynamic(() => import('./account/login'));
const AccountOrdersHistory = dynamic(() => import('./account/orders'));
const ResetPassword = dynamic(() => import('./account/reset-password'));
const Checkout = dynamic(() => import('./checkout'));
const ThankYou = dynamic(() => import('../../ui/thank-you'));
const ProductDetails = dynamic(() => import('./products/details'));
const ProductTileCarouselTastic = dynamic(() => import('./pattern-library/product-tile-carousel'));
const ProductList = dynamic(() => import('./products/list'));
const Showcase = dynamic(() => import('./showcase'));
const Cart = dynamic(() => import('./cart'));
const ForgotPassword = dynamic(() => import('./account/forgot-password'));
const WelcomeTastic = dynamic(() => import('./home/welcome'));
const SessionTimeoutTastic = dynamic(() => import('./account/session-timeout'));

export const tastics: { [key: string]: ComponentType<any> } = {
  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/content/spacer': SpacerTastic,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/pattern-library/product-tile-carousel': ProductTileCarouselTastic,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/not-found': NotFoundTastic,
  'header/region-selector-bar': RegionSelectorBarTastic,
  'header/lulu-header': LuluHeaderTastic,
  'header/lulu-header-nav': LuluHeaderNavTastic,
  'header/simple-header': SimpleHeaderTastic,
  'commercetools/ui/account/forgot-password': ForgotPassword,
  'homepage/welcome-message': WelcomeTastic,
  'commercetools/ui/pattern-library/product-promotion-carousel': ProductPromotionCarouselTastic,
  'commercetools/ui/pattern-library/category-promotion-carousel': CategoryPromotionCarouselTastic,
  'homepage/quick-links-group': QuickLinkGroupTastic,
  'homepage/whats-new-card-group': WhatsNewCardGroupTastic,
  'footer/size-guide': SizeGuideTastic,
  'customer-support/faq': FaqTastic,
  'customer-support/legal-page': LegalPageTastic,
  'footer/detailed-footer': DetailedFooterTastic,
  'footer/simple-footer': SimpleFooterTastic,
  'customer-support/contact-us': ContactUsTastic,
  'customer-support/under-maintenance': UnderMaintenanceTastic,
  'ui/session-timeout': SessionTimeoutTastic,
  default: Missing,
};
