import { useState } from 'react';
export const MICROFORM_INIT_ERROR = 'Microform initialization error:';

export const useMicroformSetup = (token: string | null) => {
  const [microformObj, setMicroformObj] = useState<any>(null);
  const [ccType, setCcType] = useState<string | undefined>(undefined);

  const initializeMicroform = () => {
    const windowObject = window as any;

    if (windowObject && (windowObject.Flex || typeof windowObject.Flex === 'function') && token) {
      try {
        const flex = new windowObject.Flex(token);
        const microform = flex.microform();
        const numberField = microform.createField('number', { placeholder: 'Enter card number' });
        const securityCode = microform.createField('securityCode', { placeholder: '•••' });

        numberField.load('#number-container');
        securityCode.load('#securityCode-container');
        setMicroformObj(microform);

        numberField.on('change', (data: any) => {
          if (data.card && data.card.length > 0) {
            setCcType(data.card[0]?.name);
          } else {
            setCcType(undefined);
          }
        });
      } catch (error) {
        console.error(MICROFORM_INIT_ERROR, error);
      }
    } else {
      console.error(`${MICROFORM_INIT_ERROR} Flex is not available`);
    }
  };

  return { microformObj, ccType, initializeMicroform };
};
