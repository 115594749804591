import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import { FooterLinkProps } from 'ui/navigation/components/detailed-footer/types';
import SimpleFooter from 'ui/navigation/components/simple-footer';

type Props = {
  data: {
    linksCol: FooterLinkProps[];
  };
};

const SimpleFooterTastic = ({ data }: Props) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <SimpleFooter links={data.linksCol} /> : null;
};

export default SimpleFooterTastic;
