import NextLink from 'next/link';
import { Link } from '@lululemon/ecom-pattern-library';
import { useLoadingOverlayContext } from 'context';
import { useNavContext } from 'context/nav';
import { useFormat } from 'helpers/hooks/useFormat';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import { useAccount } from 'frontastic/hooks';
import styles from './activities.module.scss';

export const Activities = () => {
  const { activeCategory, handleCloseDetailedNav } = useNavContext();
  const { account } = useAccount();
  const { primaryInventoryChannelId, priceChannelId } = account?.companyProfile || {};
  const { setLoading } = useLoadingOverlayContext();
  const { isFeatureEnabled } = useFeatureFlags();
  const showActivitiesLoader = isFeatureEnabled(FEATURE_FLAG_LIST.ENABLE_LOADER_FOR_NAV_BY_ACTIVITY);

  const handleActivityClick = () => {
    if (showActivitiesLoader) {
      handleCloseDetailedNav();
      setLoading(true);
    }
  };
  const { formatMessage } = useFormat({ name: 'common' });

  const byActivityText = formatMessage({
    id: 'nav.by.activity',
    defaultMessage: 'By Activity',
  });

  const shopAllText = formatMessage({
    id: 'nav.shop.all',
    defaultMessage: 'Shop All {category}',
    values: { category: activeCategory?.l1MainCategoryDisplayName ?? '' },
  });

  const list = activeCategory?.activityGroup
    ?.flatMap(({ activityItem }) => activityItem)
    .slice(0, 6)
    .map(({ name, value }) => {
      const formattedName = name.replace(/,/g, ' & ');
      const urlParams = new URLSearchParams();
      if (primaryInventoryChannelId && priceChannelId) {
        urlParams.set('pic', primaryInventoryChannelId);
        urlParams.set('prc', priceChannelId);
      }
      urlParams.append('facets[variants.attributes.activity][terms][0]', value);
      return {
        name: formattedName,
        href: `${activeCategory?.path}?${urlParams.toString()}`,
      };
    });

  if (!list || !list.length || !activeCategory?.path) {
    return null;
  }

  return (
    <>
      <p className={styles.activityLabel}>{byActivityText}</p>
      <ul key={activeCategory?.path} className={styles.activitiesList}>
        {list.map(({ name, href }) => (
          <li key={href}>
            <Link variant="underlineAnimated" className={styles.activityLink} onClick={handleActivityClick}>
              <NextLink href={href}>{name}</NextLink>
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.shopAll}>
        <Link variant="arrowBold" direction="right" className={styles.shopAllLink} onClick={handleActivityClick}>
          <NextLink href={activeCategory?.path}>{shopAllText}</NextLink>
        </Link>
      </div>
    </>
  );
};
