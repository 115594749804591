import classnames from 'classnames';
import { useNavContext } from 'context/nav';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import { Activities } from './activities';
import styles from './detailed-nav.module.scss';
import { Features } from './features';
import { MainDetails } from './main-details';

export const DetailedNav = () => {
  const { activeCategory, handleCloseDetailedNav } = useNavContext();
  const { isFeatureEnabled } = useFeatureFlags();

  const hasFeatures = !!activeCategory?.hasFeatures;
  const showDetailedNav = !!activeCategory?.subCategories?.length;
  const showActivities = isFeatureEnabled(FEATURE_FLAG_LIST.NAV_BY_ACTIVITY) && !!activeCategory?.activityGroup?.length;

  return (
    <div
      data-testid="nav-desktop__details_test-id"
      className={classnames(styles.detailedNavOuter, {
        [styles.showDetailedNav]: showDetailedNav,
      })}
      onMouseLeave={handleCloseDetailedNav}
    >
      {showDetailedNav && (
        <>
          <div className={styles.detailedNavRow}>
            <div className={styles.detailedNavInner}>
              {hasFeatures && <Features />}
              <MainDetails />
            </div>
          </div>
          {showActivities && (
            <div className={styles.detailedNavActivitiesRow}>
              <div className={styles.detailedNavActivities}>
                <Activities />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
