import { useState } from 'react';
import useCybersource from 'frontastic/hooks/useCybersource';
import { useAccount } from 'frontastic';

export const useSaveCC = (
  generatedPartnerUUID: string,
  toggleModalVisibility: (e: MouseEvent) => void,
  updateNotification: (isError: boolean) => void,
) => {
  const { saveTokenCC } = useCybersource();
  const { account } = useAccount();
  const [isLoading, setLoading] = useState(false);
  const [saveTokenError, setSaveTokenError] = useState({
    isError: false,
    message: '',
  });

  const closeModal = (e?: MouseEvent | React.FormEvent) => {
    setLoading(false);
    toggleModalVisibility(e as MouseEvent);
  };

  const saveCC = async (formData: any, token: string) => {
    setLoading(true);
    const requestData = {
      data: [
        {
          type: 'payments',
          attributes: {
            partnerIdUUID: generatedPartnerUUID,
            jwttoken: token,
            cardHolderName: formData.nameOnCard,
            cardBillingAddressId: formData.cardBillingAddressId,
            isCardPrimary: formData.isCardPrimary,
            email: account?.email ?? '',
          },
        },
      ],
    };

    try {
      const response = await saveTokenCC(requestData);
      if (response.isError) {
        updateNotification(true);
        closeModal();
      } else {
        setSaveTokenError({
          isError: false,
          message: '',
        });
        updateNotification(false);
        closeModal();
      }
    } catch (error: any) {
      updateNotification(true);
      setSaveTokenError({ isError: true, message: error.message });
    } finally {
      setLoading(false);
    }
  };

  return { saveCC, closeModal, isLoading, saveTokenError };
};
