import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import CategoryPromotionCarousel, { CategoryData } from 'ui/home/category-promotion-carousel';

const CategoryPromotionCarouselTastic = ({ data }: CategoryData) => {
  const { isFeatureEnabled } = useFeatureFlags();

  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <CategoryPromotionCarousel data={data} /> : null;
};

export default CategoryPromotionCarouselTastic;
