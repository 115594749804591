import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import { DetailedFooterWrapper, DataProps } from 'ui/navigation/components/detailed-footer';

const DetailedFooterTastic = ({ data }: DataProps) => {
  const { isFeatureEnabled } = useFeatureFlags();

  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <DetailedFooterWrapper data={data} /> : null;
};

export default DetailedFooterTastic;
