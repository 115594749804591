import { cloneElement } from 'react';
import { renderContentfulComponent, Tabpanel, Table } from '@lululemon/ecom-pattern-library';
import classNames from 'classnames';
import styles from './size-guide-primary-content.module.scss';
import { PrimaryContent } from '../types';
import { getTableSizeGrade, modifyContent } from '../utils';

type Props = {
  customStyles?: any;
  primaryContent: PrimaryContent;
  productDisplayName?: string;
  productSizeGrade?: string;
  sizeGuideCategory?: string;
  [key: string]: any;
};

export const SizeGuidePrimaryContent = ({
  customStyles = {},
  primaryContent,
  productDisplayName = '',
  productSizeGrade,
  sizeGuideCategory,
  ...extraProps
}: Props) => {
  if (primaryContent) {
    modifyContent(primaryContent, sizeGuideCategory);
  }

  if (!primaryContent) {
    return null;
  }

  return (
    <section
      className={classNames(styles.primaryContent, 'lll-font-weight-regular', customStyles?.primaryContent)}
      data-testid="size-guide__primary-content-section_test-id"
      {...extraProps}
    >
      {renderContentfulComponent({
        contentfulComponent: primaryContent,
        options: {
          renderContentType: {
            webTabpanel: ({ data, children }: { data: any; children: any }) => (
              <Tabpanel {...data} className={customStyles.tabPanel || ''}>
                {children}
              </Tabpanel>
            ),
            webTable: ({
              data: { columnHeading, rows },
              contentfulComponent,
            }: {
              data: { columnHeading: string; rows: any[] };
              contentfulComponent: any;
            }) => {
              const contourFitType = 'Contour Fit';
              const contourFitTypeFr = 'Coupe contour';
              const tableName = contentfulComponent?.fields?.contentfulEntryName || '';
              const isContourFitLeggings =
                productDisplayName.includes(contourFitType) || productDisplayName.includes(contourFitTypeFr);
              const isContourFitTable = tableName.includes(contourFitType);
              const tableStyles = {
                tableCell: customStyles?.tableCell || styles.tableCell,
                tableColHeader: customStyles?.tableColHeader || styles.tableColHeader,
                tableContainer: customStyles?.tableContainer || styles.tableContainer,
                table: customStyles?.table || styles.table,
              };

              if (
                (isContourFitLeggings && isContourFitTable) ||
                productSizeGrade === getTableSizeGrade(tableName) ||
                !productSizeGrade
              ) {
                return <Table classes={tableStyles} columnHeading={columnHeading} rows={rows} />;
              }
              return null;
            },
          },
          transformElement: ({ element, contentType }: { element: any; contentType: any }) => {
            if (contentType === 'link') {
              return cloneElement(element, {
                className: classNames(element.props.className, styles.link),
              });
            }

            if (contentType === 'richTextBody') {
              return cloneElement(element, {
                className: classNames(element.props.className, customStyles?.richTextBody || styles.richTextBody),
              });
            }

            if (contentType === 'heading') {
              return cloneElement(element, {
                className: classNames(element.props.className, 'lll-font-weight-semibold'),
                wrapperClassName: customStyles?.headingContainer,
              });
            }

            return element;
          },
        },
      })}
    </section>
  );
};
