import { FEATURE_FLAG_LIST } from 'hooks';
import { getFeatureFlag } from 'hooks/useFeatureFlags';
import { NgcCartResponseMapper } from 'shared/types/ngcCart';
import { NgcErrorCodes_RUNTIME, NgcErrorCodes_STATIC } from 'shared/utils/ngc-error-codes';
import { CybersourceErrorCode, findReasonCode } from './cybersourceReasonCodeMapper';

// TODO: remove this function once useCart hook is removed
export const parseNGCResponseForCart = (response: any): NgcCartResponseMapper => {
  return {
    isError: response.isError,
    error: response.isError ? JSON.parse(response.error.message.split('Error: ')[1]) : undefined,
    data: !response.isError ? response.data : undefined,
  };
};

export enum GenericErrorCodes {
  CHECKOUT_TIMEOUT_ERROR = '599',
  SERVICE_UNAVAILABLE_ERROR = '503',
}

type NgcErrorCodesType = typeof NgcErrorCodes_RUNTIME & typeof NgcErrorCodes_STATIC;

export const NgcErrorCodes: NgcErrorCodesType = new Proxy({} as NgcErrorCodesType, {
  get(_, prop: keyof typeof NgcErrorCodes_RUNTIME) {
    const selectedErrorCodes = getFeatureFlag(FEATURE_FLAG_LIST.ENABLE_COMMON_MODULES)
      ? NgcErrorCodes_RUNTIME
      : NgcErrorCodes_STATIC;
    return (selectedErrorCodes as Record<string, string>)[prop];
  },
});

export const cybersourceErrorMapping: Record<string, CybersourceErrorCode> = {
  'INVALID_REQUEST | MISSING_FIELD': CybersourceErrorCode.MISSING_FIELD,
  'INVALID_REQUEST | INVALID_DATA': CybersourceErrorCode.INVALID_DATA,
  'INVALID_REQUEST | DUPLICATE_REQUEST': CybersourceErrorCode.DUPLICATE_REQUEST,
  'PARTIAL_AUTHORIZED | N/A': CybersourceErrorCode.PARTIAL_APPROVAL,
  'SERVER_ERROR | SYSTEM_ERROR': CybersourceErrorCode.SYSTEM_ERROR,
  'SERVER_ERROR | SERVER_TIMEOUT': CybersourceErrorCode.SERVER_TIMEOUT,
  'AUTHORIZED_PENDING_REVIEW | AVS_FAILED': CybersourceErrorCode.AVS_FAILED,
  'AUTHORIZED_PENDING_REVIEW | CONTACT_PROCESSOR': CybersourceErrorCode.CONTACT_PROCESSOR,
  'DECLINED | EXPIRED_CARD': CybersourceErrorCode.EXPIRED_CARD,
  'DECLINED | PROCESSOR_DECLINED': CybersourceErrorCode.PROCESSOR_DECLINED,
  'DECLINED | INSUFFICIENT_FUND': CybersourceErrorCode.INSUFFICIENT_FUNDS,
  'DECLINED | STOLEN_LOST_CARD': CybersourceErrorCode.STOLEN_LOST_CARD,
  'DECLINED | ISSUER_UNAVAILABLE': CybersourceErrorCode.ISSUER_UNAVAILABLE,
  'DECLINED | UNAUTHORIZED_CARD': CybersourceErrorCode.UNAUTHORIZED_CARD,
  'DECLINED | CVN_NOT_MATCH': CybersourceErrorCode.CVN_NOT_MATCH,
  'DECLINED | EXCEEDS_CREDIT_LIMIT': CybersourceErrorCode.EXCEEDS_CREDIT_LIMIT,
  'DECLINED | INVALID_CVN': CybersourceErrorCode.INVALID_CVN,
  'DECLINED | DECLINED_CHECK': CybersourceErrorCode.DECLINED_CHECK,
  'DECLINED | BLACKLISTED_CUSTOMER': CybersourceErrorCode.BLACKLISTED_CUSTOMER,
  'DECLINED | SUSPENDED_ACCOUNT': CybersourceErrorCode.SUSPENDED_ACCOUNT,
  'AUTHORIZED_PENDING_REVIEW | CV_FAILED': CybersourceErrorCode.CV_FAILED,
  'DECLINED | INVALID_ACCOUNT': CybersourceErrorCode.INVALID_ACCOUNT,
  'INVALID_REQUEST | CARD_TYPE_NOT_ACCEPTED': CybersourceErrorCode.CARD_TYPE_NOT_ACCEPTED,
  'DECLINED | GENERAL_DECLINE': CybersourceErrorCode.GENERAL_DECLINE,
  'INVALID_REQUEST | INVALID_MERCHANT_CONFIGURATION': CybersourceErrorCode.INVALID_MERCHANT_CONFIGURATION,
  'INVALID_REQUEST | EXCEEDS_AUTH_AMOUNT': CybersourceErrorCode.EXCEEDS_AUTH_AMOUNT,
  'INVALID_REQUEST | PROCESSOR_UNAVAILABLE': CybersourceErrorCode.PROCESSOR_UNAVAILABLE,
  'INVALID_REQUEST | AUTH_ALREADY_REVERSED': CybersourceErrorCode.AUTH_ALREADY_REVERSED,
  'INVALID_REQUEST | TRANSACTION_ALREADY_SETTLED': CybersourceErrorCode.TRANSACTION_ALREADY_SETTLED,
  'INVALID_REQUEST | INVALID_AMOUNT': CybersourceErrorCode.INVALID_AMOUNT,
  'INVALID_REQUEST | INVALID_CARD_TYPE': CybersourceErrorCode.INVALID_CARD_TYPE,
  'INVALID_REQUEST | INVALID_PAYMENT_ID': CybersourceErrorCode.INVALID_PAYMENT_ID,
  'INVALID_REQUEST | MISSING_AUTH': CybersourceErrorCode.MISSING_AUTH,
  'INVALID_REQUEST | TRANSACTION_ALREADY_REVERSED_OR_SETTLED':
    CybersourceErrorCode.TRANSACTION_ALREADY_REVERSED_OR_SETTLED,
  'INVALID_REQUEST | NOT_VOIDABLE': CybersourceErrorCode.NOT_VOIDABLE,
  'INVALID_REQUEST | CAPTURE_ALREADY_VOIDED': CybersourceErrorCode.CAPTURE_ALREADY_VOIDED,
  'DECLINED | BOLETO_DECLINED': CybersourceErrorCode.BOLETO_DECLINED,
  'SERVER_ERROR | PROCESSOR_TIMEOUT': CybersourceErrorCode.PROCESSOR_TIMEOUT,
  'DECLINED | DEBIT_CARD_USAGE_LIMIT_EXCEEDED': CybersourceErrorCode.DEBIT_CARD_USAGE_LIMIT_EXCEEDED,
  'INVALID_REQUEST | ACCOUNT_NOT_ALLOWED_CREDIT': CybersourceErrorCode.ACCOUNT_NOT_ALLOWED_CREDIT,
  'REJECTED | SCORE_EXCEEDS_THRESHOLD': CybersourceErrorCode.SCORE_EXCEEDS_THRESHOLD,
  'DECLINED | APARTMENT_NUMBER_NOT_FOUND': CybersourceErrorCode.APARTMENT_NUMBER_NOT_FOUND,
  'DECLINED | INSUFFICIENT_ADDRESS_INFORMATION': CybersourceErrorCode.INSUFFICIENT_ADDRESS_INFORMATION,
  'DECLINED | HOUSE_OR_BOX_NUMBER_NOT_FOUND': CybersourceErrorCode.HOUSE_OR_BOX_NUMBER_NOT_FOUND,
  'DECLINED | MULTIPLE_ADDRESS_MATCHES': CybersourceErrorCode.MULTIPLE_ADDRESS_MATCHES,
  'DECLINED | BOX_NUMBER_NOT_FOUND': CybersourceErrorCode.BOX_NUMBER_NOT_FOUND,
  'DECLINED | ROUTE_SERVICE_NOT_FOUND': CybersourceErrorCode.ROUTE_SERVICE_NOT_FOUND,
  'DECLINED | STREET_NAME_NOT_FOUND': CybersourceErrorCode.STREET_NAME_NOT_FOUND,
  'DECLINED | POSTAL_CODE_NOT_FOUND': CybersourceErrorCode.POSTAL_CODE_NOT_FOUND,
  'DECLINED | UNVERIFIABLE_ADDRESS': CybersourceErrorCode.UNVERIFIABLE_ADDRESS,
  'DECLINED | MULTIPLE_ADDRESS_MATCHES_INTERNATIONAL': CybersourceErrorCode.MULTIPLE_ADDRESS_MATCHES_INTERNATIONAL,
  'DECLINED | ADDRESS_MATCH_NOT_FOUND': CybersourceErrorCode.ADDRESS_MATCH_NOT_FOUND,
  'DECLINED | UNSUPPORTED_CHARACTER_SET': CybersourceErrorCode.UNSUPPORTED_CHARACTER_SET,
  'PENDING_AUTHENTICATION | CONSUMER_AUTHENTICATION_REQUIRED': CybersourceErrorCode.CONSUMER_AUTHENTICATION_REQUIRED,
  'DECLINED | CONSUMER_AUTHENTICATION_FAILED': CybersourceErrorCode.CONSUMER_AUTHENTICATION_FAILED,
  'DECLINED | CUSTOMER_AUTHENTICATION_REQUIRED': CybersourceErrorCode.CUSTOMER_AUTHENTICATION_REQUIRED,
  'AUTHORIZED_PENDING_REVIEW | DECISION_PROFILE_REVIEW': CybersourceErrorCode.DECISION_PROFILE_REVIEW,
  'AUTHORIZED_RISK_DECLINED | DECISION_PROFILE_REJECT': CybersourceErrorCode.DECISION_PROFILE_REJECT,
  'DECLINED | RISK_CONTROL_DECLINE': CybersourceErrorCode.RISK_CONTROL_DECLINE,
  'DECLINED | CUSTOMER_WATCHLIST_MATCH': CybersourceErrorCode.CUSTOMER_WATCHLIST_MATCH,
  'DECLINED | ADDRESS_COUNTRY_WATCHLIST_MATCH': CybersourceErrorCode.ADDRESS_COUNTRY_WATCHLIST_MATCH,
  'DECLINED | EMAIL_COUNTRY_WATCHLIST_MATCH': CybersourceErrorCode.EMAIL_COUNTRY_WATCHLIST_MATCH,
  'DECLINED | IP_COUNTRY_WATCHLIST_MATCH': CybersourceErrorCode.IP_COUNTRY_WATCHLIST_MATCH,
};

export const getCybersourceErrorCode = (errors: any): number | null => {
  if (!errors || errors?.length === 0) return null;
  const errorMsg = errors[0].message.split(' | ');
  const key = errorMsg.length == 2 ? errorMsg[0] : errorMsg.slice(0, 2).join(' | ');
  return findReasonCode(key);
};

export enum ERROR_TYPE {
  GENERIC_ERROR,
  SHIPPING_ERROR,
  PAYMENT_ERROR,
  PLACE_ORDER_ERROR,
}

export type errorObj = {
  errors: { message: string }[];
  code: string;
};

export const ngcErrorInitialState: errorObj = { errors: [{ message: '' }], code: '' };
