import { FC } from 'react';
import { DialogModal } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';

type ShippingTaxErrorModalProps = {
  isShippingTaxErrorOpen: boolean;
  setIsShippingTaxErrorOpen: (value: boolean) => void;
  updateTaxCalculationHandler: () => void;
};

const ShippingTaxErrorModal: FC<ShippingTaxErrorModalProps> = ({
  isShippingTaxErrorOpen,
  setIsShippingTaxErrorOpen,
  updateTaxCalculationHandler,
}) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const title = formatMessage({ id: 'tax.calculation.failed', defaultMessage: 'Tax calculation failed' });
  const primaryCta = formatMessage({ id: 'tax.calculation.failed.cta', defaultMessage: 'Try Again' });
  const message = formatMessage({
    id: 'tax.calculation.failed.message',
    defaultMessage: 'We’re currently unable to calculate the tax amount. Please try again.',
  });
  const close = formatMessage({ id: 'close', defaultMessage: 'Close' });

  const ctaTaxErrorHandler = () => {
    setIsShippingTaxErrorOpen(false);
    updateTaxCalculationHandler();
  };

  return (
    <div data-testid="shipping-tax__error-modal-container_test-id">
      <DialogModal
        visible={isShippingTaxErrorOpen}
        messages={{ close }}
        primaryCta={{ children: primaryCta, onClick: ctaTaxErrorHandler }}
        title={title}
        titleId="dialog-modal-checkout-error"
        data-testid="shipping-tax__error-modal-wrapper_test-id"
        onRequestClose={() => setIsShippingTaxErrorOpen(false)}
      >
        <p className="lll-text-body-2" data-testid="shipping-tax__error-modal-message_test-id">
          {message}
        </p>
      </DialogModal>
    </div>
  );
};

export default ShippingTaxErrorModal;
