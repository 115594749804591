import React, { FC } from 'react';
import NextLink from 'next/link';
import { location16Icon, Link } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import { useBusinessUnit } from 'frontastic';

const ShippingLocation: FC = () => {
  const { formatMessage } = useFormat({ name: 'account' });
  const { defaultShippingAddress } = useBusinessUnit();
  const { isFeatureEnabled } = useFeatureFlags();
  const isCSVFeatureFlagEnabled = isFeatureEnabled(FEATURE_FLAG_LIST.ORDER_DETAILS_CSV);
  const {
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    postalCode = '',
    country = '',
  } = defaultShippingAddress || {};

  const displayDefaultShippingAddressOld =
    defaultShippingAddress &&
    `${defaultShippingAddress?.streetNumber || ''} ${defaultShippingAddress?.streetName || ''}, ${
      defaultShippingAddress?.city || ''
    }, ${defaultShippingAddress?.state || ''} ${defaultShippingAddress?.postalCode || ''}, ${
      defaultShippingAddress?.country || ''
    }`;

  const displayDefaultShippingAddressWithFeatureFlag =
    defaultShippingAddress && `${addressLine1} ${addressLine2}, ${city}, ${state} ${postalCode}, ${country}`;

  const displayDefaultShippingAddress = isCSVFeatureFlagEnabled
    ? displayDefaultShippingAddressWithFeatureFlag
    : displayDefaultShippingAddressOld;

  return (
    <Link
      className="flex cursor-pointer items-baseline"
      icon={location16Icon}
      id="top-nav-shipping-location"
      tag="div"
      textStyle="body-3"
      variant="underlineAnimated"
    >
      <NextLink href="/account#addresses">
        {displayDefaultShippingAddress
          ? displayDefaultShippingAddress
          : formatMessage({
              id: 'add.shipping.address',
              defaultMessage: 'Add Shipping Address',
            })}
      </NextLink>
    </Link>
  );
};

export default ShippingLocation;
