import { NoProductsFound } from 'ui/no-products-found';
import ProductPromotionCarousel from 'ui/pattern-library/product-promotion-carousel';
import { Data } from 'ui/pattern-library/product-promotion-carousel/types';

const ProductPromotionCarouselTastic = ({ data }: Data) => {
  if (!data?.data?.dataSource?.items?.length) return <NoProductsFound />;
  return <ProductPromotionCarousel data={data} />;
};

export default ProductPromotionCarouselTastic;
