import NextLink from 'next/link';
import { Link } from '@lululemon/ecom-pattern-library';
import { Account } from 'shared/types/account';
import { Level3NavItem } from 'shared/types/nav';
import { Category } from 'shared/types/product';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { getPath } from '.';
import styles from './main-details.module.scss';

type MainDetailsSubCategoryListProps = {
  account: Account | undefined;
  limit: number;
  categories: Level3NavItem[];
  setDetailRefs: (index: number, columnIndex: number, extra: number) => any | undefined;
  columnIndex: number;
  extra?: number;
  handleCloseDetailedNav: () => void;
};

export const MainDetailsSubCategoryList = ({
  account,
  limit,
  categories,
  setDetailRefs,
  columnIndex,
  extra = 0,
  handleCloseDetailedNav,
}: MainDetailsSubCategoryListProps) => {
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const handleOnClick = (category: Category) => {
    handleCloseDetailedNav();
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.HEADER_SUB_NAV_CLICK(category.name));
  };

  return (
    <>
      {categories?.map((category, index) => (
        <li key={category.categoryId}>
          {category.path && (
            <Link
              tag="div"
              className={styles.mainDetailsSubCategory}
              variant="underlineAnimated"
              onClick={() => handleOnClick(category)}
            >
              <NextLink href={getPath(account, limit, category)}>
                <button ref={setDetailRefs(index, columnIndex, extra)} tabIndex={-1}>
                  {category?.l3SubcategoryLinkDisplayName}
                </button>
              </NextLink>
            </Link>
          )}
        </li>
      ))}
    </>
  );
};
