import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import QuickLinksGroup, { QuickLinksProps } from 'ui/home/quick-links-group';

type Props = {
  data: QuickLinksProps;
};

const QuickLinksGroupTastic = ({ data }: Props) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <QuickLinksGroup {...data} /> : null;
};

export default QuickLinksGroupTastic;
