import Search from 'components/search';
import { useCart } from 'context';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import { useAccount } from 'frontastic';
import styles from './secondary-nav.module.scss';
import AccountButton from '../account-button';
import QuickViewBag from '../quick-view-bag';

export const SecondaryNav = () => {
  const { cart } = useCart();
  const { account } = useAccount();
  const userRole = account?.role?.key.toLowerCase();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <div className={styles.siteSecondaryNav}>
      <ul className={styles.siteSecondaryList}>
        <li>
          <Search />
        </li>
        <li>
          <AccountButton />
        </li>
        {isFeatureEnabled(FEATURE_FLAG_LIST.CART) && userRole !== 'finance' && (
          <li>
            <QuickViewBag cartCount={cart?.totalQuantity ?? 0} cart={cart} />
          </li>
        )}
      </ul>
    </div>
  );
};
