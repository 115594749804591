const NgcErrorCodes_STATIC = {
  CART_NOT_FOUND: "404211",
  OPERATION_NOT_PERMITTED: "403001",
  SHIPPING_ADDRESS_NOT_FOUND: "404521",
  SHIPPING_METHOD_NOT_FOUND: "404504",
  RESOURCE_NOT_FOUND: "405070",
  SHIPPING_ADDRESS_NULL: "4004100",
  SHIPPING_METHOD_ERROR: "404521",
  SHIPPING_METHOD_NOT_ENABLED_OR_ALLOWED: "4004101",
  DATA_MISSING: "5004521",
  SHIPPING_RATE_INPUT_UPDATE_FAILED: "4004010",
  DATA_LIST_SIZE_DOES_NOT_MATCH: "400408",
  DISCOUNT_CODE_DOES_NOT_EXIST: "400474",
  DISCOUNT_CODE_TIME_RANGE_NOT_APPLICABLE: "400464",
  DISCOUNT_CODE_NOT_APPLICABLE: "400466",
  DISCOUNT_CODE_MAX_APPLICATION_REACHED: "400468",
  MAX_ALLOWED_DISCOUNT_CODE_ERROR: "400470",
  DISCOUNT_CODE_NOT_FOUND: "404429",
  GET_SESSIONS_ERROR: "4005049",
  CARD_BILLING_ADDRESS_INVALID: "4005068",
  EMAIL_INVALID: "4005070",
  CARD_HOLDER_NAME_INVALID: "4005067",
  JWT_TOKEN_INVALID: "4005066",
  PARTNERUUID_INVALID: "4005065",
  LINEITEM_PRICE_CANNOT_BE_ZERO: "4006001",
  LINEITEM_PRICE_CANNOT_BE_NULL: "4006002",
  MISSING_SHIPPING_METHOD: "4006011",
  INVALID_SHIPPIN_ADDRESS_FIELD: "4006012",
  PAYMENT_INFO_NOT_FOUND: "4006014",
  INVALID_TOTAL_LINE_ITEM_QUANTITY: "4006015",
  ORDER_PRICE_CANNOT_BE_ZERO: "4006017",
  MULTIPLE_PAYMENTS_ON_THE_CART: "4006018",
  INVALID_BILLING_ADDRESS_FIELD: "4006019",
  AUTHORIZE_PAYMENT_ERROR: "4006020",
  INVALID_CURRENCY: "4006013",
  ORDER_INTERNAL_SERVER_ERROR: "5004005",
  ORDER_ITEMS_UNAVAILABLE: "4006022",
  CYBERSOURCE_PAYMENT_AUTH_FAILED: "4005171",
  CART_INACTIVE: "400410",
  CART_ALREADY_ORDERED: "4006024",
  UNKNOWN_ERROR_CODE: "UNKNOWN_ERROR_CODE",
}

const NgcErrorCodes_RUNTIME = Object.assign(
  {
    RUNTIME_VALUE: "TESTING_RUNTIME_VALUE_NOT_REAL_ERROR_CODE",
  },
  NgcErrorCodes_STATIC
)

export { NgcErrorCodes_STATIC, NgcErrorCodes_RUNTIME }
