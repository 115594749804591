import { createContext, PropsWithChildren } from 'react';
import { Market, HandleMarketProps } from 'shared/types/header';
import useMarket from 'helpers/hooks/useMarket';

const initialMarketState = {
  market: {} as Market,
  markets: [] as Market[],
  handleMarket: {} as ({ market }: HandleMarketProps) => void,
};

const MarketContext = createContext(initialMarketState);

// Ignoring eslint rule because PropsWithChildren<Record<string, never>> throws error
// it expects Element instead of never
/* eslint-disable-next-line */
type Props = PropsWithChildren<{}>;

const MarketProvider = ({ children }: Props) => {
  const { market, markets, handleMarket } = useMarket();

  return (
    <MarketContext.Provider value={{ market: market as Market, markets, handleMarket }}>
      {children}
    </MarketContext.Provider>
  );
};

export { MarketContext, MarketProvider };
