import cs from 'classnames';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import { Column } from './column';
import styles from './detailed-footer.module.scss';
import { Legal } from './legal';
import { FooterColumnProps, FooterLinkProps } from './types';

export type DataProps = {
  data: {
    linksCol1: FooterLinkProps[];
    linksCol2: FooterLinkProps[];
    linksCol3: FooterLinkProps[];
    linksCol4: FooterLinkProps[];
    linksCol5: FooterLinkProps[];
    headerCol1: string;
    headerCol2: string;
    headerCol3: string;
    headerCol4: string;
    headerCol5: string;
  };
};

export const DetailedFooterWrapper = ({ data }: DataProps) => {
  const columns = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
    },
    {
      header: data.headerCol3,
      links: data.linksCol3,
    },
    {
      header: data.headerCol4,
      links: data.linksCol4,
    },
    {
      header: data.headerCol5,
      links: data.linksCol5,
    },
  ];

  return <DetailedFooter columns={columns} />;
};

export const DetailedFooter = ({ columns }: { columns: FooterColumnProps[] }) => {
  return (
    <footer aria-labelledby="footer-heading" data-testid="footer_test-id" className={styles.footer}>
      <div className={cs('lll-grid', styles.gridContainer)} data-testid="footer__grid-container_test-id">
        {columns.slice(0, 4).map((column, index) => (
          <Column columnIndex={index} column={column} key={index} />
        ))}
        <Legal columns={columns} />
      </div>

      {/* Accordion will only be displayed on mobile & tablet <992px */}
      <div className={styles.accordion} data-testid="footer__accordion-container_test-id">
        {columns.slice(0, 4).map((column, index) => (
          <Accordion
            variant="arrow"
            closedSectionTitle={column.header}
            openSectionTitle={column.header}
            buttonClassName={styles.accordionButton}
            iconClassName={styles.accordionIcon}
            key={index}
          >
            <Column columnIndex={index} column={column} />
          </Accordion>
        ))}
        <Legal columns={columns} />
      </div>
    </footer>
  );
};
