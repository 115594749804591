import { ComponentProps } from 'react';
import { Button, Link } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from '../../account.module.scss';

type Props = ComponentProps<'div'> & {
  variant?: 'save' | 'delete';
  disabled?: boolean;
  onSave?: (e: React.FormEvent) => void;
  onCancel: () => void;
  displayCancelLink?: boolean;
  saveButtonText?: string;
  testId?: string;
  secondaryTestId?: string;
  displayCancelLinkText?: string;
};

const SaveOrCancel = ({
  variant = 'save',
  disabled,
  onSave,
  onCancel,
  displayCancelLink = true,
  saveButtonText,
  testId,
  secondaryTestId,
  displayCancelLinkText,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <div className={styles.wrapperContainer}>
      {variant == 'save' ? (
        <Button disabled={disabled} kind="primary" className="flex-1" onClick={onSave} data-testid={testId}>
          {saveButtonText ? saveButtonText : formatMessage({ id: 'save', defaultMessage: 'Save' })}
        </Button>
      ) : (
        <Button disabled={disabled} className="flex-1 uppercase" onClick={onSave} data-testid={testId}>
          {formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
        </Button>
      )}

      {displayCancelLink && (
        <Link
          tag="button"
          variant="underline"
          className={styles.modalCancelButton}
          onClick={onCancel}
          data-testid={secondaryTestId}
        >
          {displayCancelLinkText ? displayCancelLinkText : formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
        </Link>
      )}
    </div>
  );
};

export default SaveOrCancel;
