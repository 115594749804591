import { ReactNode, useEffect, useState, useRef } from 'react';
import { checkMarkIcon, Icon, caratIcon, closeIcon, Pill } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './combobox.module.scss';

/**
 * Additional Props:
 * customText(in Options): prop can be passed if we want to render a
 * custom value when a particular option is selected.
 *
 * selectedOption: prop can be used in case we want to make
 * the combobox component controlled
 * */
export type Option = {
  value: string;
  label: string;
  isDefaultValue: boolean;
  customText?: () => string | JSX.Element;
};

type CustomClasses = {
  input?: string;
  inputWrapper?: string;
};

type Props = {
  required?: boolean;
  placeholder?: string;
  options: Option[];
  label?: string | JSX.Element;
  classes?: CustomClasses;
  prefix?: string | JSX.Element;
  onChange: (option: Option) => void;
  suffix?: string | JSX.Element;
  name: string;
  selectedOption?: Option;
  initialSelectedItem: Option;
  searchHandler: (searchTxt: string) => Option[];
  description?: ReactNode;
  noResultsFoundContent?: React.JSX.Element;
};

export const itemToString = (item: Option | null) => item?.label || '';

const Combobox = ({
  options,
  name,
  onChange,
  searchHandler,
  initialSelectedItem,
  label,
  description,
  noResultsFoundContent,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const [inputValue, setInputValue] = useState(initialSelectedItem?.label);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);
  const [items, setItems] = useState(options);

  const inputRef = useRef<HTMLInputElement>(null);

  const inputChangeHandler = (data: UseComboboxStateChange<Option>) => {
    const value = data?.inputValue || '';
    setItems(searchHandler(value));
    setInputValue(value);
  };

  const { getToggleButtonProps, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps, isOpen } =
    useCombobox({
      items,
      inputValue,
      initialSelectedItem,
      selectedItem,
      onSelectedItemChange: ({ selectedItem: newSelectedItem, type }) => {
        if (type === useCombobox.stateChangeTypes.InputBlur || !newSelectedItem?.value) {
          return;
        }

        setSelectedItem(newSelectedItem);
        return onChange(newSelectedItem);
      },
      itemToString,
      onInputValueChange: inputChangeHandler,
      onIsOpenChange: ({ isOpen, selectedItem }) => {
        if (!isOpen) {
          return selectedItem?.label ? setInputValue(selectedItem.label) : setInputValue(initialSelectedItem.label);
        }
      },
      stateReducer: (state, action) => {
        const { changes, type } = action;

        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              selectedItem,
              inputValue,
            };
          default:
            return changes;
        }
      },
    });

  useEffect(() => {
    setItems(options);
  }, [isOpen]);

  useEffect(() => {
    if (!inputValue) {
      setItems(options);
    }
  }, [inputValue]);

  return (
    <div data-testid={`${name}__combobox-container_test-id`} className={styles.container}>
      {label && (
        <label className={styles.label} {...getLabelProps()} data-testid={`${name}__combobox-label_test-id`}>
          {label}
        </label>
      )}
      <span>{description}</span>

      <form
        className={styles.inputBoxContainer}
        onSubmit={(e) => e.preventDefault()}
        onBlur={(event) => {
          const insideTarget = event.currentTarget.contains(event.relatedTarget); // means no child elements of the form were focused

          return !insideTarget && setInputValue(selectedItem.label);
        }}
      >
        <input
          {...getInputProps({ ref: inputRef })}
          className={cx(styles.input, { [styles.bold]: isOpen })}
          data-testid={`${name}__combobox-input_test-id`}
        />
        <button
          onClick={() => {
            setInputValue('');
            return inputRef && inputRef.current && inputRef.current.focus();
          }}
          className={cx(styles.closeIcon, {
            [styles.hide]: !(isOpen && inputValue?.length > 0),
          })}
          aria-label={formatMessage({ id: 'clear.selection', defaultMessage: 'clear selection' })}
          type="button"
          data-testid={`${name}__close-button_test-id`}
        >
          <Icon content={closeIcon} />
        </button>

        {!isOpen && selectedItem?.isDefaultValue && (
          <Pill kind="secondary" data-testid={`${name}__default_test-id`}>
            {formatMessage({ id: 'default', defaultMessage: 'DEFAULT' })}
          </Pill>
        )}

        <button
          type="button"
          {...getToggleButtonProps()}
          className={styles.toggleButton}
          data-testid={`${name}__toggle-button_test-id`}
        >
          <Icon content={caratIcon} className={cx({ [styles.carat]: isOpen })} />
        </button>
      </form>

      <ul
        className={cx(styles.menu, {
          [styles.menuOpen]: isOpen,
        })}
        data-testid={`${name}__combobox-options-list_test-id`}
        {...getMenuProps()}
      >
        {items.length ? (
          items.map((item, index) => {
            const isSelected = selectedItem?.value === item.value;

            return (
              <li
                key={item.label}
                data-testid={`${name}__options-menu-items-${index}_test-id`}
                className={cx(styles.menuItem, {
                  [styles.highlighted]: highlightedIndex === index,
                  [styles.selected]: isSelected,
                })}
                {...getItemProps({ item, index })}
              >
                <div className={styles.itemLabel}>{item.customText?.() || item.label}</div>
                <div className={styles.menuItemContent}>
                  <div className={styles.defaultPill}>
                    {item.isDefaultValue && (
                      <Pill kind="secondary" data-testid={`${name}__default_test-id`}>
                        {formatMessage({ id: 'default', defaultMessage: 'DEFAULT' })}
                      </Pill>
                    )}
                  </div>
                  <div className={styles.check}>
                    {isSelected && <Icon data-testid={`${name}__select-check-mark_test-id`} content={checkMarkIcon} />}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <span className={styles.noResultsContainer}>{noResultsFoundContent}</span>
        )}
      </ul>
    </div>
  );
};

export default Combobox;
