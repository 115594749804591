import NextLink from 'next/link';
import { useRouter } from 'next/router';
import cs from 'classnames';
import { NavItem } from 'shared/types/nav';
import { useNavContext } from 'context/nav';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './primary-nav.module.scss';

export const PrimaryNavItem = ({ navItem, innerRef, index }: { navItem: NavItem; innerRef: any; index: number }) => {
  const router = useRouter();
  const { account } = useAccount();
  const { l1MainCategoryDisplayName = '', l1MainCategorySlug } = navItem;
  const { activeCategory, handleMouseEnter, navActivable, handleCloseDetailedNav } = useNavContext();
  const isActive = activeCategory?.l1MainCategorySlug === l1MainCategorySlug;
  const { limitStep } = useProductList();
  const categoryParams = categoryQueryParams(account, limitStep, null);
  //category link with all query params
  const categoryPath = `${navItem.path || navItem.l1MainCategorySlug}?${categoryParams}`;
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  const onClickLink = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.PRIMARY_NAV_ITEM_CLICKED(navItem.name));
    navActivable.current = false;
    handleCloseDetailedNav();
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCloseDetailedNav();
      router.push(categoryPath || '');
      trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.PRIMARY_NAV_ITEM_CLICKED(navItem.name));
    }
  };

  const handleMouseLeave = () => {
    navActivable.current = true;
  };

  return (
    <div
      onFocus={() => handleMouseEnter(navItem, index, true)}
      onMouseEnter={() => handleMouseEnter(navItem, index)}
      onMouseLeave={handleMouseLeave}
      onKeyDown={onKeyDown}
      data-testid="primary-nav__item_test-id"
    >
      <NextLink href={categoryPath || ''}>
        <a
          className={cs(styles.underline, styles.anchor, styles.primaryNavAnchor, {
            [styles.primaryNavAnchorActive]: isActive,
          })}
          ref={innerRef}
          onClick={onClickLink}
        >
          {l1MainCategoryDisplayName}
        </a>
      </NextLink>
    </div>
  );
};
