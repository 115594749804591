import FAQ, { FaqProps } from '../../../ui/customer-support/components/faq';

type Props = {
  data: FaqProps;
};

const FaqTastic = ({ data }: Props) => {
  return <FAQ {...data} />;
};

export default FaqTastic;
