import { useState, useEffect } from 'react';
import { Heading, Image, GridContainer, Link, LinkProvider } from '@lululemon/ecom-pattern-library';
import Skeleton from 'react-loading-skeleton';
import { ProductCard, CardInfoProps } from 'shared/types/homePage';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { ConditionalLink } from 'helpers/cocoLink';
import { useProduct } from 'helpers/hooks/useProduct';
import { toProductCollectionList } from 'helpers/mappers/toProductCollectionList';
import { HOME_PAGE } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { Reference } from 'types/reference';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './whats-new-card.module.scss';

type WhatsNewCardLinksProps = {
  linkTitle: string;
  reference: Reference;
};

type WhatsNewCardProps = {
  heading: string;
  subHeading: string;
  gender: string;
  categorySlug: string;
  product: ProductCard[];
  whatsNewCardLinks: WhatsNewCardLinksProps[];
};
export type WhatsNewCardsProps = {
  cards: WhatsNewCardProps[];
};

const WhatsNewCardGroup = ({ cards }: WhatsNewCardsProps) => {
  const { categories, getProductsForWhatsNewCard } = useProduct();
  const [isLoading, setLoading] = useState(false);
  const [whatsNewCardData, setWhatsNewCardData] = useState<WhatsNewCardProps[]>([]);
  const { account } = useAccount();
  const { limitStep } = useProductList();
  //category link with all query params
  const categoryParams = categoryQueryParams(account, limitStep, null);
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      if (cards.length && categories.length) {
        try {
          const cardInfo: CardInfoProps[] = [];

          cards.map((card) => {
            const categoryResult = categories.find((item) => {
              return item.slug == card.categorySlug;
            });

            if (categoryResult?.categoryId) {
              const categoryIds: string[] = [categoryResult?.categoryId];

              if (categoryResult?.subCategories?.length) {
                categoryResult?.subCategories.map((subCategory) => {
                  if (subCategory?.categoryId) categoryIds.push(subCategory?.categoryId);
                });
              }
              cardInfo.push({ categoryIds, categorySlug: card.categorySlug, gender: card.gender });
            }
          });

          const response = await Promise.all(cardInfo.map((info) => getProductsForWhatsNewCard([info])));

          const productMap = new Map();

          if (!response[0]?.isError && Array.isArray(response[0]) && response[0].length > 0) {
            const firstCategory = response[0][0];
            productMap.set(firstCategory.categorySlug, firstCategory.products);
          }

          if (!response[1]?.isError && Array.isArray(response[1]) && response[1].length > 0) {
            const secondCategory = response[1][0];
            productMap.set(secondCategory.categorySlug, secondCategory.products);
          }

          cards.forEach((card) => {
            const productList = productMap.get(card.categorySlug);
            card.product = productList || [];
          });

          setWhatsNewCardData(cards);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [categories.length]);

  return (
    <GridContainer className="pt-16" layout="2 Columns - Wide Split">
      {isLoading
        ? cards?.map((link: WhatsNewCardProps, index) => <Skeleton key={index} className="h-[900px]" count={1} />)
        : whatsNewCardData?.length > 0 &&
          whatsNewCardData?.map(
            (card: WhatsNewCardProps, index) =>
              card?.product?.length > 0 && (
                <div
                  className="lll-card flex flex-col"
                  key={card?.heading}
                  data-testid={`whats-new-cards-group__product-card-${index}_test-id`}
                >
                  <Heading
                    data-testid="whats-new-cards-group__heading_test-id"
                    className="lll-text-small"
                    subHeading={card?.subHeading}
                    tag="h3"
                  >
                    {card?.heading}
                  </Heading>

                  <div className="my-16 flex h-fit flex-row">
                    <div className={styles.gridContainer}>
                      <LinkProvider component={ConditionalLink}>
                        {card?.product?.map((product: ProductCard) => (
                          <Link
                            href={`${product?._url}&color=${product.colourCode || ''}`}
                            key={product?.name}
                            onClick={() => {
                              trackEvent(
                                EVENT_CATEGORY.PRODUCT_INTERACTION,
                                HOME_PAGE.WHATS_NEW_CARD_CLICKED(
                                  toProductCollectionList([product], 'whats-new', 'product-tile'),
                                  product?.name,
                                ),
                              );
                            }}
                          >
                            <Image
                              className="h-full cursor-pointer object-cover"
                              src={product?.images?.src}
                              alt={product?.images?.alt}
                              lazy={false}
                            />
                          </Link>
                        ))}
                      </LinkProvider>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <LinkProvider component={ConditionalLink}>
                      {card?.whatsNewCardLinks?.map((link: WhatsNewCardLinksProps, index) => (
                        <Link
                          variant="underline"
                          className="pt-4"
                          fontWeight="medium"
                          key={link?.linkTitle}
                          data-testid={`whats-new-cards-group__link-${card?.heading}-${index}_test-id`}
                          href={
                            link?.reference.type === 'link'
                              ? `${link.reference.link}?${categoryParams}`
                              : `${link.reference.pageFolder._url}?${categoryParams}`
                          }
                          onClick={() => {
                            trackEvent(
                              EVENT_CATEGORY.COMPONENT_EVENT,
                              HOME_PAGE.WHATS_NEW_CARD_LINK_CLICKED(link?.linkTitle),
                            );
                          }}
                        >
                          {link?.linkTitle}
                        </Link>
                      ))}
                    </LinkProvider>
                  </div>
                </div>
              ),
          )}
    </GridContainer>
  );
};

export default WhatsNewCardGroup;
