import { Modal, Heading } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import { ModalCyberSourceRequestMapper } from 'frontastic/hooks/useCybersource/types';
import SaveOrCancel from '../../../components/save-or-cancel';

type Props = {
  modalProps: ModalCyberSourceRequestMapper;
  closeWarningMessageModal: (modalProps: ModalCyberSourceRequestMapper) => void;
};

const WarningMessageModal = ({ modalProps, closeWarningMessageModal }: Props) => {
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const closeModal = (modalProps: ModalCyberSourceRequestMapper) => {
    closeWarningMessageModal(modalProps);
  };

  return (
    <>
      <Modal
        aria-label="Warning Message Modal"
        onRequestClose={() => {
          closeModal(modalProps);
        }}
        visible={modalProps.isWarningMessageModalOpen}
        classes={{
          modal: 'min-w-[27rem]',
        }}
        data-testid="warning-message__modal_test-id"
      >
        <div className="w-min-423">
          <Heading className="lll-text-xsmall mb-16" tag="h1" data-testid="warning-message__heading_test-id">
            {formatPaymentMessage({
              id: 'remove.cannot.edit_card',
              defaultMessage: 'Card can not be removed.',
            })}
          </Heading>
          <div className="lll-text-body-1 ml-5 mr-16 pt-8">
            {formatPaymentMessage({
              id: 'error.message.one.credit_card',
              defaultMessage:
                'You currently have only one credit card on file. Please add a new card before attempting to remove this one.',
            })}
          </div>
          <div className="mt-16 flex items-center justify-between">
            <SaveOrCancel
              onCancel={() => {}}
              onSave={() => {
                closeModal(modalProps);
              }}
              variant="save"
              saveButtonText={formatPaymentMessage({
                id: 'OK',
                defaultMessage: 'OK',
              })}
              displayCancelLink={false}
              testId="warning-message__ok-button_test-id"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WarningMessageModal;
