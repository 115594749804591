import { useEffect, useCallback } from 'react';
import { NavItem } from 'shared/types/nav';
import { useNavContext } from 'context/nav';
import { createRefMap } from 'helpers/utils/nav-ref-map';
import { PrimaryNavItem } from './primary-nav-item';
import styles from './primary-nav.module.scss';

export const PrimaryNav = ({ navData, menuLength }: { navData: NavItem[]; menuLength: number }) => {
  const { getRef, setRef } = createRefMap();
  const {
    handleCloseDetailedNav,
    activeL1Index,
    setActiveL2Index,
    activeL2Index,
    setFeaturesActivated,
    setSubcategoriesActivated,
    tabActivated,
    activeCategory,
    featuresActivated,
    subcategoriesActivated,
  } = useNavContext();
  const hasFeatures = !!activeCategory?.hasFeatures;

  const handleKeyDown = useCallback(
    (evt) => {
      switch (evt.key) {
        case 'ArrowDown': {
          evt.preventDefault();
          if (hasFeatures) {
            setFeaturesActivated(true);
          } else {
            setSubcategoriesActivated(true);
          }
          setActiveL2Index(0);
          break;
        }
        case 'Tab': {
          // NO PREVENT DEFAULT HERE SO THAT TABBING IS NOT BLOCKED FOR L1s
          if ((activeL1Index !== null && activeL1Index + 1 === menuLength) || (evt.shiftKey && activeL1Index === 0)) {
            handleCloseDetailedNav();
          }
          break;
        }
        default:
          break;
      }
    },
    [hasFeatures, activeL1Index],
  );

  useEffect(() => {
    if (tabActivated && !featuresActivated && !subcategoriesActivated) {
      const endOfMenu = activeL1Index === menuLength;
      if (endOfMenu) {
        handleCloseDetailedNav();
      } else {
        getRef(`l1-${activeL1Index}`)?.current.focus();
      }
    }
  }, [activeL1Index, tabActivated, activeL2Index, menuLength, featuresActivated, subcategoriesActivated]);

  return (
    <div className={styles.sitePrimaryNav} onKeyDown={handleKeyDown} data-testid="primary-nav__wrapper_test-id">
      <ul className={styles.primaryNavList}>
        {navData?.map((item, index) => (
          <li key={item?.categoryId}>
            <PrimaryNavItem navItem={item} index={index} innerRef={setRef(`l1-${index}`)} />
          </li>
        ))}
      </ul>
    </div>
  );
};
