import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { DialogModal } from '@lululemon/ecom-pattern-library';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import { CybersourceErrorCode } from 'helpers/utils/cybersourceReasonCodeMapper';
import {
  ERROR_TYPE,
  errorObj,
  GenericErrorCodes,
  getCybersourceErrorCode,
  NgcErrorCodes,
} from 'helpers/utils/ngcResponseUtil';
import { CheckoutStep } from '../../index';

type PlaceOrderErrorModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  placeOrderCtaErrorHandler: (stepToMove: CheckoutStep) => void;
  errorObj: errorObj;
  placeOrderHandler: (value: boolean) => Promise<any>;
};

const PlaceOrderErrorModal: FC<PlaceOrderErrorModalProps> = ({
  isOpen,
  setIsOpen,
  errorObj,
  placeOrderCtaErrorHandler,
  placeOrderHandler,
}) => {
  const router = useRouter();
  const { resetCart } = useCart();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const [errorCodeCtaStep, setErrorCodeCtaStep] = useState(ERROR_TYPE.GENERIC_ERROR);

  // Default Error Modal Content
  const close = formatMessage({ id: 'close', defaultMessage: 'Close' });
  const [title, setTitle] = useState(
    formatMessage({ id: 'unable.to.checkout.title', defaultMessage: 'Unable to checkout' }),
  );
  const [message, setMessage] = useState(
    formatMessage({
      id: 'order.submit.generic.error.message',
      defaultMessage: 'There was an issue completing checkout, please try again.',
    }),
  );
  const [primaryCta, setPrimaryCta] = useState(
    formatMessage({ id: 'order.submit.error.cta', defaultMessage: 'Back to bag' }),
  );

  const setErrorMessageForPlaceOrder = (errorObj: errorObj) => {
    const errorCode = errorObj.code?.toString();
    const errors = errorObj.errors;
    switch (errorCode) {
      case NgcErrorCodes.ORDER_ITEMS_UNAVAILABLE:
        setTitle(formatMessage({ id: 'unable.to.checkout', defaultMessage: 'Unable to Checkout' }));
        setMessage(
          formatMessage({
            id: 'order.submit.error.message',
            defaultMessage: 'Remove sold out items and adjust quantities to proceed to checkout.',
          }),
        );
        setPrimaryCta(formatMessage({ id: 'order.submit.error.cta', defaultMessage: 'Back to bag' }));
        setErrorCodeCtaStep(ERROR_TYPE.GENERIC_ERROR);
        break;

      case NgcErrorCodes.LINEITEM_PRICE_CANNOT_BE_ZERO:
      case NgcErrorCodes.LINEITEM_PRICE_CANNOT_BE_NULL:
      case NgcErrorCodes.INVALID_TOTAL_LINE_ITEM_QUANTITY:
        setTitle(formatMessage({ id: 'unable.to.checkout', defaultMessage: 'Unable to Checkout' }));
        setMessage(
          formatMessage({
            id: 'order.submit.generic.error.message',
            defaultMessage: 'There was an issue completing checkout, please try again.',
          }),
        );
        setPrimaryCta(formatMessage({ id: 'order.submit.error.cta', defaultMessage: 'Back to bag' }));
        setErrorCodeCtaStep(ERROR_TYPE.GENERIC_ERROR);
        break;

      case NgcErrorCodes.MISSING_SHIPPING_METHOD:
      case NgcErrorCodes.INVALID_SHIPPIN_ADDRESS_FIELD:
        setTitle(formatMessage({ id: 'unable.to.checkout', defaultMessage: 'Unable to Checkout' }));
        setMessage(
          formatMessage({
            id: 'order.submit.shipping.error.message',
            defaultMessage: 'There was an issue saving your shipping information, please try again.',
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'order.submit.shipping.error.cta',
            defaultMessage: 'back to shipping information',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.SHIPPING_ERROR);
        break;

      case NgcErrorCodes.PAYMENT_INFO_NOT_FOUND:
      case NgcErrorCodes.MULTIPLE_PAYMENTS_ON_THE_CART:
      case NgcErrorCodes.INVALID_BILLING_ADDRESS_FIELD:
      case NgcErrorCodes.AUTHORIZE_PAYMENT_ERROR:
        setTitle(formatMessage({ id: 'unable.to.checkout', defaultMessage: 'Unable to Checkout' }));
        setMessage(
          formatMessage({
            id: 'order.submit.payment.error.message',
            defaultMessage: 'There was an issue processing your payment information, please try again.',
          }),
        );
        setPrimaryCta(
          formatMessage({ id: 'order.submit.payment.error.cta', defaultMessage: 'back to payment method' }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      case NgcErrorCodes.GET_SESSIONS_ERROR:
      case NgcErrorCodes.CYBERSOURCE_PAYMENT_AUTH_FAILED:
        const cybersourceError = getCybersourceErrorCode(errors);
        handleCybersourceErrors(cybersourceError);
        break;
      case GenericErrorCodes.CHECKOUT_TIMEOUT_ERROR:
        setErrorCodeCtaStep(ERROR_TYPE.PLACE_ORDER_ERROR);
        setTitle(formatMessage({ id: 'order.submit.timeout.error.title', defaultMessage: 'Checkout in progress' }));
        setMessage(
          formatMessage({
            id: 'order.submit.timeout.error.message',
            defaultMessage:
              'Processing your order may take a few minutes. Do not refresh the page, close the tab or browser.',
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'order.submit.timeout.cta',
            defaultMessage: 'GOT IT',
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleCybersourceErrors = (cybersourceError: CybersourceErrorCode | null) => {
    switch (cybersourceError) {
      case CybersourceErrorCode.MISSING_FIELD:
      case CybersourceErrorCode.INVALID_DATA:
        setTitle(formatMessage({ id: 'incomplete.card.info', defaultMessage: 'Incomplete card information' }));
        setMessage(
          formatMessage({
            id: 'incomplete.card.info.message',
            defaultMessage:
              'Some of your card information is missing. Try another card or contact your Account Manager to add the missing information.',
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'order.submit.payment.error.cta',
            defaultMessage: 'back to payment method',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      case CybersourceErrorCode.PARTIAL_APPROVAL:
        setTitle(formatMessage({ id: 'card.not.processed', defaultMessage: "Card wasn't processed" }));
        setMessage(
          formatMessage({
            id: 'card.not.processed.message',
            defaultMessage: "We couldn't process your card. Please try a different card to complete your order.",
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'order.submit.payment.error.cta',
            defaultMessage: 'back to payment method',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      case CybersourceErrorCode.SYSTEM_ERROR:
      case CybersourceErrorCode.SERVER_TIMEOUT:
      case CybersourceErrorCode.ISSUER_UNAVAILABLE:
      case CybersourceErrorCode.PROCESSOR_UNAVAILABLE:
      case CybersourceErrorCode.PROCESSOR_TIMEOUT:
        setTitle(formatMessage({ id: 'server.error', defaultMessage: 'Something went wrong' }));
        setMessage(
          formatMessage({
            id: 'server.error.message',
            defaultMessage:
              'Our payment system is experiencing technical difficulties. Please try again or contact your Account Manager for assistance.',
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'place.orders',
            defaultMessage: 'Place Order',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      case CybersourceErrorCode.CONTACT_PROCESSOR:
      case CybersourceErrorCode.PROCESSOR_DECLINED:
      case CybersourceErrorCode.INSUFFICIENT_FUNDS:
      case CybersourceErrorCode.STOLEN_LOST_CARD:
      case CybersourceErrorCode.UNAUTHORIZED_CARD:
      case CybersourceErrorCode.EXCEEDS_CREDIT_LIMIT:
      case CybersourceErrorCode.DECLINED_CHECK:
      case CybersourceErrorCode.BLACKLISTED_CUSTOMER:
      case CybersourceErrorCode.SUSPENDED_ACCOUNT:
      case CybersourceErrorCode.GENERAL_DECLINE:
      case CybersourceErrorCode.INVALID_MERCHANT_CONFIGURATION:
        setTitle(formatMessage({ id: 'payment.declined', defaultMessage: 'Payment declined' }));
        setMessage(
          formatMessage({
            id: 'payment.declined.message',
            defaultMessage: "Your payment didn't go through. Please select a different card and try again.",
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'order.submit.payment.error.cta',
            defaultMessage: 'back to payment method',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      case CybersourceErrorCode.DECISION_PROFILE_REJECT:
        setTitle(formatMessage({ id: 'unable.to.checkout', defaultMessage: 'Unable to Checkout' }));
        setMessage(
          formatMessage({
            id: 'unable.to.checkout.message',
            defaultMessage: 'There was an issue completing checkout, please try again.',
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'place.orders',
            defaultMessage: 'Place Order',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      case CybersourceErrorCode.AVS_FAILED:
        setTitle(formatMessage({ id: 'invalid.shipping.address', defaultMessage: 'Invalid shipping address' }));
        setMessage(
          formatMessage({
            id: 'invalid.shipping.address.message',
            defaultMessage:
              'Make sure the address is correct or select another shipping address. To modify the address, please contact your account manager.',
          }),
        );
        setPrimaryCta(
          formatMessage({
            id: 'order.submit.shipping.error.cta',
            defaultMessage: 'back to shipping information',
          }),
        );
        setErrorCodeCtaStep(ERROR_TYPE.PAYMENT_ERROR);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setErrorMessageForPlaceOrder(errorObj);
  }, [errorObj]);

  const ctaErrorHandler = () => {
    if (errorCodeCtaStep === ERROR_TYPE.GENERIC_ERROR) {
      resetCart();
      router.push('/cart');
    } else if (errorCodeCtaStep === ERROR_TYPE.SHIPPING_ERROR) {
      placeOrderCtaErrorHandler(CheckoutStep.SHIPPING);
    } else if (errorCodeCtaStep === ERROR_TYPE.PAYMENT_ERROR) {
      placeOrderCtaErrorHandler(CheckoutStep.PAYMENT);
    } else if (errorCodeCtaStep === ERROR_TYPE.PLACE_ORDER_ERROR) {
      placeOrderHandler(true);
    }
    setIsOpen(false);
  };

  return (
    <div data-testid="place-order__error-modal-container_test-id">
      <DialogModal
        visible={isOpen}
        messages={{ close }}
        primaryCta={{ children: primaryCta, onClick: ctaErrorHandler }}
        title={title}
        titleId="dialog-modal-checkout-error"
        onRequestClose={() => setIsOpen(false)}
      >
        <p className="lll-text-body-1" data-testid="place-order__error-modal-message_test-id">
          {message}
        </p>
      </DialogModal>
    </div>
  );
};

export default PlaceOrderErrorModal;
