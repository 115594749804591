import NextLink from 'next/link';
import { Icon, yogoRedIcon } from '@lululemon/ecom-pattern-library';

type Props = {
  logoLink: string;
  onClick?: () => void;
  size?: number;
};

export const SiteLogo = ({ logoLink, onClick, size = 34 }: Props) => (
  <NextLink href={logoLink}>
    <a onClick={onClick}>
      <Icon height={size} width={size} content={yogoRedIcon} />
      <span className="lll-hidden-visually">lululemon athletica</span>
    </a>
  </NextLink>
);
