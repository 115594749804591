import { useEffect } from 'react';
import { StudioData } from 'shared/types/nav';
import { Category } from 'shared/types/product/Category';
import HeaderContainer from 'components/commercetools-ui/organisms/lulu-header';
import { MarketProvider } from 'context/market';
import { NavProvider } from 'context/nav';
import { useStudioDataContext } from 'context/studioData';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import SimpleHeader from 'ui/simple-header';

type Props = {
  data: StudioData;
  categories: Category[];
};

const LuluHeaderNavTastic = ({ data: studioData, categories }: Props) => {
  const { data, setData } = useStudioDataContext();
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    setData({ ...data, navData: studioData });
  }, []);

  const featureFlag = isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE);
  return featureFlag ? (
    <MarketProvider>
      <NavProvider>
        <div id="header-container">
          <HeaderContainer studioData={studioData} categories={categories} />
        </div>
      </NavProvider>
    </MarketProvider>
  ) : (
    <SimpleHeader />
  );
};
export default LuluHeaderNavTastic;
