import { useMemo, useState, cloneElement } from 'react';
import { Tablist, renderContentfulComponent } from '@lululemon/ecom-pattern-library';
import classNames from 'classnames';
import styles from './general-size-guide.module.scss';
import { SizeGuideCategories } from '../size-guide-categories';
import { SizeGuideProps } from '../types';
import { getFilteredCategories, getActiveCategoryIdx } from '../utils';

type Props = {
  currentSlug: string;
  setCurrentSlug: (slug: string) => void;
  sizeGuideData: SizeGuideProps;
};

export const GeneralSizeGuide = ({ currentSlug, setCurrentSlug, sizeGuideData }: Props) => {
  const { pageLinks, sizeGuideTitle, sizeGuideCategories } = sizeGuideData;

  const [isUpdated, setIsUpdated] = useState(false);
  const [activePrimaryCategoryIdx, setActivePrimaryCategoryIdx] = useState(
    getActiveCategoryIdx(pageLinks, currentSlug),
  );
  const [activeSecondaryCategoryIdx, setActiveSecondaryCategoryIdx] = useState(0);

  const primaryCategoryTabLists = useMemo(
    () =>
      pageLinks.map((item) => ({
        webRoute: item.fields.webRoute,
        callToAction: item.fields.callToAction,
      })),
    [pageLinks],
  );

  const handleSelectSecondaryCategory = (idx: number) => {
    setActiveSecondaryCategoryIdx(idx);
  };

  const handleSelectPrimaryCategory = async (webRoute: string, idx: number) => {
    setActiveSecondaryCategoryIdx(0);
    setActivePrimaryCategoryIdx(idx);

    const parts = webRoute.split('/');
    const categorySlug = parts[parts.length - 1];

    setCurrentSlug(categorySlug);
  };

  return (
    <div data-testid="size-guide__content_test-id">
      {renderContentfulComponent({
        contentfulComponent: sizeGuideTitle,
        options: {
          transformElement: ({ element }: { element: React.ReactElement }) => {
            const extraProps: any = {};
            extraProps.className = classNames(element.props.className, styles.title);
            extraProps['data-testid'] = 'general-size-guide-title';
            return cloneElement(element, extraProps);
          },
        },
      })}
      <Tablist selectedIndex={activePrimaryCategoryIdx} inViewIndex={activePrimaryCategoryIdx} role="tablist">
        {primaryCategoryTabLists?.map(({ webRoute, callToAction }, idx) => (
          <button
            data-testid={`general-size-guide__tablist-${callToAction}_test-id`}
            key={callToAction + idx}
            onClick={() => handleSelectPrimaryCategory(webRoute, idx)}
            type="button"
          >
            {callToAction}
          </button>
        ))}
      </Tablist>
      <SizeGuideCategories
        activePanelIdx={activeSecondaryCategoryIdx}
        isUpdated={isUpdated}
        setActivePanelIdx={handleSelectSecondaryCategory}
        setIsUpdated={setIsUpdated}
        sizeGuideCategoriesData={getFilteredCategories(sizeGuideCategories)}
      />
    </div>
  );
};
