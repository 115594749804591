import { useCallback } from 'react';

export type PasswordSpecification = {
  ruleText: string;
  isValid: boolean;
};

export type PasswordSpecificationRules = {
  length: PasswordSpecification;
  character: PasswordSpecification;
  case: PasswordSpecification;
  digit: PasswordSpecification;
  different?: PasswordSpecification;
};

export const getPasswordRulesData = (isChangePasswordFlow: boolean): PasswordSpecificationRules => {
  const minimumSetOfRules = {
    length: {
      ruleText: '12 characters',
      isValid: false,
    },
    character: {
      ruleText: '1 special character',
      isValid: false,
    },
    case: {
      ruleText: '1 uppercase',
      isValid: false,
    },
    digit: {
      ruleText: '1 digit',
      isValid: false,
    },
  };
  // Only apply validation `different` when there is change password flow, not the reset new password.
  if (isChangePasswordFlow) {
    return {
      ...minimumSetOfRules,
      different: {
        ruleText: 'Current and new password must be different',
        isValid: false,
      },
    };
  } else {
    return minimumSetOfRules;
  }
};
const useValidate = () => {
  const validateTextExists = useCallback((name: string) => name.length >= 1, []);

  const validatePassword = useCallback((password: string, oldPassword?: string) => {
    const isChangePasswordFlow = oldPassword !== undefined;
    const passwordRules = getPasswordRulesData(isChangePasswordFlow);

    if (typeof password !== 'string') {
      return { passwordSpecificationList: passwordRules, isValid: false };
    }

    let hasUpperCase = false;
    let hasDigit = false;
    let hasSpecialChar = false;

    // Iterate over the password string to check for character types
    // Can use regex but going with iteration because SAST throwing warnings with all possible regex patterns.
    // This approach is also more performant for short and medium length passwords.
    for (let i = 0; i < password.length; i++) {
      const char = password[i];
      if (char >= 'A' && char <= 'Z') hasUpperCase = true;
      else if (char >= '0' && char <= '9') hasDigit = true;
      else if (!(char >= 'a' && char <= 'z')) hasSpecialChar = true; // If not a-z, it's special
    }

    const isDifferent = isChangePasswordFlow && validateTextExists(password) && oldPassword !== password;
    const criteriaMet = [hasUpperCase, hasDigit, hasSpecialChar, isDifferent].filter(Boolean).length;
    const criteriaMetValue = isChangePasswordFlow ? 4 : 3;

    // Get password rule requirements
    passwordRules.length.isValid = password.length >= 12;
    passwordRules.character.isValid = hasSpecialChar;
    passwordRules.case.isValid = hasUpperCase;
    passwordRules.digit.isValid = hasDigit;

    if (isChangePasswordFlow && passwordRules?.different) {
      passwordRules.different.isValid = isDifferent;
    }

    return {
      passwordSpecificationList: passwordRules,
      isValid: criteriaMet === criteriaMetValue && passwordRules.length.isValid,
    };
  }, []);

  const validateEmail = useCallback((email: string) => {
    const emailRules =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRules.test(email);
  }, []);

  const validatePostalCode = useCallback((value: string) => Number.isInteger(Number(value)) && value.length === 5, []);

  const validatePhoneNumber = useCallback((value: string) => {
    const phonenumberRules = /^\d{10}$/;
    return phonenumberRules.test(value);
  }, []);

  const validateExpiryDate = useCallback((dateString: string) => {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!regex.test(dateString)) {
      return false; // Invalid format
    }

    const [inputMonth, inputYear] = dateString.split('/').map(Number);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
    // Compare with the current month and year
    if (inputYear < currentYear || (inputYear === currentYear && inputMonth <= currentMonth)) {
      return false;
    }

    return true;
  }, []);

  const validateCalculatedExpiryDate = useCallback((inputMonth: number, inputYear: number) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentYear = currentDate.getFullYear(); // Get last two digits of the year
    // Compare with the current month and year
    if (inputYear < currentYear || (inputYear === currentYear && inputMonth <= currentMonth)) {
      return false;
    }

    return true;
  }, []);

  const isNonEmptyString = useCallback((str: string) => str.trim().length > 0, []);

  return {
    validatePassword,
    validateEmail,
    validateTextExists,
    validatePostalCode,
    validatePhoneNumber,
    validateExpiryDate,
    validateCalculatedExpiryDate,
    isNonEmptyString,
  };
};

export default useValidate;
