import { FilterTile } from '@lululemon/ecom-pattern-library';
import styles from './size-guide-categories.module.scss';
import { SizeGuidePrimaryContent } from '../size-guide-primary-content';
import { SizeGuideCategory } from '../types';
import { createEncodedKey, toKebabCase } from '../utils';

type Props = {
  activePanelIdx: number;
  isUpdated: boolean;
  setActivePanelIdx: (idx: number, sizeGuideCategoriesData?: any) => void;
  setIsUpdated: (updated: boolean) => void;
  sizeGuideCategoriesData: SizeGuideCategory[];
};

export const SizeGuideCategories = ({
  activePanelIdx = 0,
  isUpdated = false,
  setActivePanelIdx,
  setIsUpdated,
  sizeGuideCategoriesData,
}: Props) => {
  if (!sizeGuideCategoriesData) {
    return null;
  }

  return (
    <>
      <div data-testid="size-guide__categories_test-id" className={styles.sizeGuideCategories}>
        <section className={styles.sizeGuideTabPanel} role="tablist">
          {sizeGuideCategoriesData?.map(({ secondaryFilterTileTitle }, idx) => (
            <FilterTile
              aria-controls={`${toKebabCase(secondaryFilterTileTitle)}-tab`}
              aria-selected={activePanelIdx === idx}
              className={styles.sizeGuideTab}
              data-testid={`size-guide__categories-filter-tab-${idx}_test-id`}
              id={toKebabCase(secondaryFilterTileTitle)}
              isSelected={activePanelIdx === idx}
              key={createEncodedKey({ secondaryFilterTileTitle, idx })}
              onClick={() => {
                setIsUpdated(!isUpdated);
                setActivePanelIdx(idx, sizeGuideCategoriesData);
              }}
              role="tab"
            >
              {secondaryFilterTileTitle}
            </FilterTile>
          ))}
        </section>
      </div>
      {sizeGuideCategoriesData?.map(({ subtitle, sizeGuideCategory, primaryContent }, idx) => (
        <SizeGuidePrimaryContent
          aria-labelledby={toKebabCase(subtitle)}
          hidden={activePanelIdx !== idx}
          key={createEncodedKey({
            category: subtitle,
            sizeGuideCategory,
            idx,
          })}
          primaryContent={primaryContent}
          sizeGuideCategory={sizeGuideCategory}
        />
      ))}
    </>
  );
};
