import { useState, useEffect, ChangeEvent } from 'react';
import { Field, Modal, Heading, Checkbox, Select, Icon, Notification } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { OverlayLoader } from 'components/overlayLoader';
import { useFormat } from 'helpers/hooks/useFormat';
import useValidate from 'helpers/hooks/useValidate';
import { getYearOptions, getCurrentMonth, getCurrentYear, getMonthOptions } from 'helpers/utils/datePicker';
import { formatAddressDisplay } from 'helpers/utils/formatAddress';
import useResolveCCImage from 'hooks/useResolveCCImage';
import { useBusinessUnit } from 'frontastic';
import { CreditCardType } from 'frontastic/hooks/useCybersource/types';
import paymentStyle from './payment-add.module.scss';
import styles from './payment-add.module.scss';
import SaveOrCancel from '../../../components/save-or-cancel';
import Script from 'next/script';
import { useTokenManagement } from 'hooks/useTokenManagement';
import { useMicroformSetup } from 'hooks/useMicroformSetup';
import { useTransientToken } from 'hooks/useTransientToken';
import { useSaveCC } from 'hooks/useSaveCC';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';

let flexMicroformURL = '';
if (process && process.env.FRONTASTIC_ENV == 'prod') {
  flexMicroformURL = 'https://flex.cybersource.com';
} else {
  flexMicroformURL = 'https://testflex.cybersource.com';
}

const restURLPart = '/cybersource/assets/microform/0.11/flex-microform.min.js';
export const mockClientLibraryOld = flexMicroformURL + restURLPart;

type Props = {
  toggleModalVisibility: (e: MouseEvent) => void;
  creditCardsList: CreditCardType[];
  updateNotification: (isError: boolean) => void;
};

export type TransformedBillingInfo = {
  label?: string;
  value?: string;
};

const PaymentAdd = ({ toggleModalVisibility, creditCardsList, updateNotification }: Props) => {
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { resolveCCImageFromBrandName } = useResolveCCImage();
  const { validateCalculatedExpiryDate, isNonEmptyString } = useValidate();
  const { businessUnit, billingAddresses } = useBusinessUnit();

  const [businessUnitData, setBusinessUnitData] = useState<TransformedBillingInfo[]>([]);

  const [isErrored, setIsErrored] = useState({
    cardBillingAddressId: false,
    expirydate: false,
    nameOnCard: false,
  } as any);

  const [displayStatus, setDisplayStatus] = useState({
    cardBillingAddressId: 'warning',
    expirydate: 'warning',
    nameOnCard: 'warning',
  });

  const [formData, setFormData] = useState<CreditCardType>({
    cardBillingAddressId: '',
    isCardPrimary: true,
    cardToken: ' ',
    cardType: '',
    expires: `${getCurrentMonth}/${getCurrentYear}`,
    maskedPan: ' ',
    nameOnCard: '',
    partnerIdUUID: '',
  });

  const [expiryMonth, setExpiryMonth] = useState(getCurrentMonth);
  const [expiryYear, setExpiryYear] = useState(getCurrentYear);

  const { isFeatureEnabled, isFeatureFlagDataAvailable } = useFeatureFlags();
  const featureFlagUpgradeCybersourceActive = isFeatureEnabled(FEATURE_FLAG_LIST.CYBERSOURCE_VERSION_UPGRADE);

  const { token, generatedPartnerUUID, captureContextError, clientLibrary, clientLibraryIntegrity } =
    useTokenManagement(businessUnit.key);
  const { microformObj, ccType, initializeMicroform } = useMicroformSetup(token);
  const { generateTransientToken, isTokenResponseError } = useTransientToken();
  const { saveCC, closeModal, isLoading, saveTokenError } = useSaveCC(
    generatedPartnerUUID,
    toggleModalVisibility,
    updateNotification,
  );

  useEffect(() => {
    if (billingAddresses.length >= 1) {
      const transformedBillingAddress: TransformedBillingInfo[] = billingAddresses.map((address) => ({
        label: formatAddressDisplay(address, false),
        value: address.addressId,
      }));
      setBusinessUnitData(transformedBillingAddress);
      setFormData((prevFormData) => ({
        ...prevFormData,
        cardBillingAddressId: billingAddresses[0].addressId || '',
      }));
    }
  }, [billingAddresses]);

  useEffect(() => {
    if (expiryYear && expiryMonth) {
      const formattedExpiryDate = `${expiryMonth}/${expiryYear}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['expires']: formattedExpiryDate,
      }));
    }
    if (validateCalculatedExpiryDate(parseInt(expiryMonth), parseInt(expiryYear))) {
      setIsErrored(
        (prevIsErrored: any) =>
          ({
            ...prevIsErrored,
            expirydate: false,
          } as any),
      );
      setDisplayStatus((prevDisplayStatus) => ({
        ...prevDisplayStatus,
        expirydate: 'warning',
      }));
    }
  }, [expiryYear, expiryMonth]);

  const handleChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectBoxChange = (e: ChangeEvent<any>) => {
    if (e.target.name == 'expiryMonth') {
      setExpiryMonth(e.target.value);
    }
    if (e.target.name == 'expiryYear') {
      setExpiryYear(e.target.value);
    }
  };

  const saveToken = () => {
    const formattedExpiryDate = `${expiryMonth}/${expiryYear}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['expires']: formattedExpiryDate,
    }));

    const validExpiryData: boolean = validateCalculatedExpiryDate(parseInt(expiryMonth), parseInt(expiryYear));
    const validNameOnCard = isNonEmptyString(formData.nameOnCard);
    const validBillingAddress = Boolean(formData.cardBillingAddressId);
    const validForm = validExpiryData && validNameOnCard && validBillingAddress;

    if (validForm) {
      generateTransientToken(microformObj, expiryMonth, expiryYear, (transientToken) => {
        saveCC(formData, transientToken);
      });
      setIsErrored(() => ({
        expirydate: false,
        nameOnCard: false,
        cardBillingAddressId: false,
      }));
      setDisplayStatus(() => ({
        expirydate: 'warning',
        nameOnCard: 'warning',
        cardBillingAddressId: 'warning',
      }));
    } else {
      if (!validNameOnCard) {
        setIsErrored((prevIsErrored: any) => ({
          ...prevIsErrored,
          nameOnCard: !validNameOnCard,
        }));
        setDisplayStatus((prevDisplayStatus) => ({
          ...prevDisplayStatus,
          nameOnCard: 'error',
        }));
      }

      if (!validExpiryData) {
        setIsErrored((prevIsErrored: any) => ({
          ...prevIsErrored,
          expirydate: !validExpiryData,
        }));
        setDisplayStatus((prevDisplayStatus) => ({
          ...prevDisplayStatus,
          expirydate: 'error',
        }));
      }
      if (!validBillingAddress) {
        setIsErrored((prevIsErrored: any) => ({
          ...prevIsErrored,
          cardBillingAddressId: !validBillingAddress,
        }));
        setDisplayStatus((prevDisplayStatus) => ({
          ...prevDisplayStatus,
          cardBillingAddressId: 'error',
        }));
      }
    }
  };

  const updateCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      isCardPrimary: checked ? checked : false,
    }));
  };

  const displayErrorMessage = () => {
    const capturnContextErrorMsg = (
      <span className="lll-text-body-2">
        {formatPaymentMessage({
          id: 'microform.load.error',
          defaultMessage: 'Unable to Load Microform',
        })}
      </span>
    );
    if (captureContextError) return capturnContextErrorMsg;
  };

  return (
    <>
      {isFeatureFlagDataAvailable && featureFlagUpgradeCybersourceActive && token && (
        <Script
          src={clientLibrary}
          integrity={clientLibraryIntegrity}
          crossOrigin="anonymous"
          type="text/javascript"
          onReady={initializeMicroform}
          async
        />
      )}
      {isFeatureFlagDataAvailable && !featureFlagUpgradeCybersourceActive && token && (
        <Script
          src={mockClientLibraryOld}
          // crossOrigin="anonymous" // we do not have to use this, because of this for the above line url, we are getiing 403 (forbidden) error sometime. so functioality not works sometimes. So commented.
          type="text/javascript"
          onReady={initializeMicroform}
          async
        />
      )}
      <Modal
        aria-label="Add new card"
        onRequestClose={(e: MouseEvent) => {
          closeModal(e);
        }}
        visible={true}
        classes={{
          modal: 'min-w-[27rem]',
        }}
        data-testid="cc-container__add-card-modal_test-id"
      >
        <div className={styles.modalContainer}>
          <Heading className="lll-text-xsmall mb-16" tag="h1" data-testid="add-card-modal__heading-test-id">
            {creditCardsList.length < 5
              ? formatPaymentMessage({
                  id: 'add.card',
                  defaultMessage: 'Add new card',
                })
              : formatPaymentMessage({
                  id: '"max.limit.warning"',
                  defaultMessage: 'You have reached the maximum limit on file.',
                })}
          </Heading>
          {creditCardsList.length < 5 ? (
            <div
              className={cx(styles.contentContainer, {
                [styles.loading]: isLoading,
              })}
            >
              {isLoading && <OverlayLoader color="red" name="payment-methods" />}
              {(captureContextError || saveTokenError?.isError) && (
                <Notification type="error" visible={true}>
                  {displayErrorMessage()}
                </Notification>
              )}
              {/* {!isLoading && ( */}
              <>
                <div className="flex flex-col">
                  <Field
                    type="text"
                    required
                    data-testid={'add-card-modal__nameOnCard-input_test-id'}
                    label={formatMessage({ id: 'name_on_card', defaultMessage: 'Name on card' })}
                    name="nameOnCard"
                    id="add-card-modal__nameOnCard-input"
                    disabled={false}
                    value={formData.nameOnCard}
                    onChange={handleChange}
                    status={displayStatus['nameOnCard']}
                    hint={isErrored['nameOnCard'] ? 'Invalid Name' : ''}
                    className={styles.flexLabelGroup}
                  >
                    <span className={styles.ModelFieldLabel}>
                      {formatMessage({ id: 'name_on_card', defaultMessage: 'Name on card' })}
                    </span>
                  </Field>
                </div>
                <div data-testid="card-number__input_test-id" className={styles.flexLabelGroup}>
                  <span className={styles.ModelFieldLabel}>
                    {formatCheckoutMessage({ id: 'card.number', defaultMessage: 'Card number' })}
                  </span>
                  <span className={paymentStyle.numberContainerParentClass}>
                    <div className={paymentStyle.numberContainerClassName} id="number-container"></div>
                    {ccType !== undefined && resolveCCImageFromBrandName(ccType) !== ' ' ? (
                      <div className={paymentStyle.ccImageIcon}>
                        <Icon
                          className="h-fit w-26"
                          title={'cardType'}
                          content={resolveCCImageFromBrandName(ccType)}
                          data-testid={'add-credit-card-cctype__icon_test-id'}
                        />
                      </div>
                    ) : (
                      ' '
                    )}
                  </span>
                </div>
                {isTokenResponseError.number && (
                  <div
                    className={styles.validationErrorMessage}
                    data-testid="add-card-modal__validation-error-message_test-id"
                  >
                    {formatMessage({
                      id: 'enter.valid.cc',
                      defaultMessage: 'Please enter a valid card number',
                    })}{' '}
                  </div>
                )}

                <div className="flex justify-between">
                  <div className={styles.flexLabelGroup}>
                    <div className={styles.ModelFieldLabel}>
                      {formatPaymentMessage({ id: 'expiration.date', defaultMessage: 'Expiration date' })}
                    </div>
                    <div className="flex items-center">
                      <div className="w-88">
                        <Select
                          id="expiry_month"
                          name="expiryMonth"
                          options={getMonthOptions()}
                          data-testid="add-payment__select-expiry-month_test-id"
                          onChange={handleSelectBoxChange}
                          value={expiryMonth}
                          status={displayStatus['expirydate']}
                          hideStatusIcon
                        >
                          {''}
                        </Select>
                      </div>
                      <div className="mx-8 text-xl"> / </div>
                      <div className="w-88">
                        <Select
                          id="expiry_year"
                          name="expiryYear"
                          options={getYearOptions()}
                          data-testid="add-payment__select-expiry-year_test-id"
                          onChange={handleSelectBoxChange}
                          value={expiryYear}
                          status={displayStatus['expirydate']}
                          hideStatusIcon
                        >
                          {''}
                        </Select>
                      </div>
                    </div>
                    {isErrored['expirydate'] && (
                      <div className={styles.errorMessage} data-testid="add-card-modal__error_test-id">
                        {formatMessage({
                          defaultMessage: 'Please enter a valid expiry date.',
                          id: 'edit.invalid.expires',
                        })}
                      </div>
                    )}
                  </div>
                  <div className={styles.flexLabelGroup}>
                    <span className={styles.ModelFieldLabel}>
                      {formatCheckoutMessage({ id: 'ccv', defaultMessage: 'CCV' })}
                    </span>
                    <div
                      className={`${paymentStyle.cvvContainer} w-121`}
                      data-testid="secureCode__input_test-id"
                      id="securityCode-container"
                    ></div>

                    {isTokenResponseError.securityCode && (
                      <div
                        className={styles.validationErrorMessage}
                        data-testid="add-card-modal__validation-error-message_test-id"
                      >
                        {formatMessage({
                          id: 'enter.valid.ccv',
                          defaultMessage: 'Please enter a valid ccv',
                        })}{' '}
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.flexLabelGroup}>
                  <Heading className="lll-text-xsmall" tag="h1">
                    {formatPaymentMessage({ id: 'billing.address', defaultMessage: 'Billing address' })}
                  </Heading>
                  <span className={styles.ModelFieldLabel}>
                    {formatPaymentMessage({
                      id: 'select.address.credit_card',
                      defaultMessage: 'Select an existing address on file',
                    })}
                  </span>

                  <Select
                    id="billing_address"
                    data-testid="add-card-modal__billing-address-select_test-id"
                    hint={isErrored['cardBillingAddressId'] ? 'Please select the billing address' : ''}
                    // messages={''}
                    placeholder="Select address..."
                    name="cardBillingAddressId"
                    onChange={handleChange}
                    options={businessUnitData}
                    required={false}
                    status={displayStatus['cardBillingAddressId']}
                    value={formData.cardBillingAddressId}
                  >
                    {''}
                  </Select>

                  <Checkbox
                    required={false}
                    id={'add_credit_card__default-checkbox'}
                    name="isCardPrimary"
                    // defaultChecked={formData.isCardPrimary}
                    checked={formData.isCardPrimary}
                    onChange={updateCheckBoxChange}
                    data-testid={'add_credit_card__default-checkbox_test-id'}
                  >
                    {formatMessage({ id: 'set.default.credit_card', defaultMessage: 'Set as default credit card' })}
                  </Checkbox>
                </div>
              </>
              {/* )} */}
              <div className="mt-16 flex items-center justify-between">
                <SaveOrCancel
                  onCancel={closeModal}
                  variant="save"
                  className="flex-col"
                  saveButtonText={formatMessage({ id: 'save.credit_card', defaultMessage: 'SAVE CREDIT CARD' })}
                  displayCancelLink={true}
                  onSave={saveToken}
                  testId="add-cc-save-card__button_test-id"
                  secondaryTestId="addCC-cancel__link_test-id"
                />
              </div>
            </div>
          ) : (
            <>
              {formatPaymentMessage({
                id: 'remove.card.continue',
                defaultMessage: 'Please Remove an existing card to continue.',
              })}
              <div className="mt-16 flex items-center justify-between">
                <SaveOrCancel
                  onCancel={closeModal}
                  variant="save"
                  className="flex-col"
                  saveButtonText={formatMessage({ id: 'ok', defaultMessage: 'OK' })}
                  displayCancelLink={false}
                  onSave={closeModal}
                  testId="add-cc-save-card-ok__button_test-id"
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PaymentAdd;
