import { useCallback, useEffect, useMemo } from 'react';
import { plusIcon, Link, RadioButton, Icon } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { Formik, Form, useFormikContext, FormikValues } from 'formik';
import { PaymentMethodPayload, useCart } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { StaticPaymentType } from 'helpers/paymentTypes';
import { getFormattedAddressinMultiline } from 'helpers/utils/formatAddress';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import useResolveCCImage from 'hooks/useResolveCCImage';
import { useBusinessUnit } from 'frontastic';
import { CreditCardType } from 'frontastic/hooks/useCybersource/types';
import styles from './creditCardList.module.scss';

type Props = {
  creditCards: CreditCardType[];
  addCreditCardHandler: (event: MouseEvent) => void;
  updatePaymentMethod: (data: PaymentMethodPayload) => void;
  openEditModal: (event: MouseEvent, selectedCardDetails: CreditCardType) => void;
};

const SubmitCreditCardOnChange = () => {
  const { values, submitForm, submitCount, dirty } = useFormikContext();

  const initialRender = !dirty && !submitCount;

  useEffect(() => {
    !initialRender && submitForm();
  }, [values]);

  return null;
};

const CreditCardList = ({ creditCards, addCreditCardHandler, updatePaymentMethod, openEditModal }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  const { resolveCCImage } = useResolveCCImage();
  const { getBillingAddressById } = useBusinessUnit();
  const { cart } = useCart();
  const { isFeatureEnabled } = useFeatureFlags();
  const isCSVFeatureFlagEnabled = isFeatureEnabled(FEATURE_FLAG_LIST.ORDER_DETAILS_CSV);
  const SELECTED_CREDIT_CARD = 'selectedCreditCard';

  const getCartDetails = useCallback(
    (partnerUUID?: string) =>
      creditCards.find((creditCard: CreditCardType) => creditCard?.partnerIdUUID === partnerUUID),
    [creditCards],
  );

  const updateSelectedCreditCard = (values: FormikValues) => {
    const cardDetails = getCartDetails(values.selectedCreditCard);

    if (cart?.payments?.partnerUUID !== values?.selectedCreditCard)
      updatePaymentMethod({
        paymentMethod: StaticPaymentType.creditCard,
        billingAddressId: cardDetails?.cardBillingAddressId,
        partnerUUID: values.selectedCreditCard,
      });
  };

  const editCardModalHandler = (event: MouseEvent, creditCardDetails: CreditCardType) => {
    event.preventDefault();
    openEditModal(event, creditCardDetails);
  };

  const showAddButton = creditCards?.length < 5;

  const initiallySelectedCard = useMemo(() => {
    const paymentMethodInCart = cart?.payments;
    const primaryCreditCard = creditCards.find((creditCard: CreditCardType) => creditCard.isCardPrimary);

    if (paymentMethodInCart?.paymentMethod) {
      const selectedCardDetails = getCartDetails(paymentMethodInCart.partnerUUID);

      if (selectedCardDetails) {
        return selectedCardDetails?.partnerIdUUID;
      } else {
        return '';
      }
    }
    return primaryCreditCard?.partnerIdUUID || '';
  }, [cart?.payments, creditCards, getCartDetails]);

  return (
    <Formik onSubmit={updateSelectedCreditCard} initialValues={{ [SELECTED_CREDIT_CARD]: initiallySelectedCard }}>
      {({ values, handleChange }) => {
        const cardDetails = getCartDetails(values.selectedCreditCard);

        const billingAddressForSelectedCC = getBillingAddressById(cardDetails?.cardBillingAddressId);

        return (
          <Form data-testid="credit-card-list__container_test-id" onChange={handleChange}>
            <SubmitCreditCardOnChange />
            <div className={styles.container}>
              {creditCards.map((creditCardDetails: CreditCardType) => {
                const last4Digits = creditCardDetails.maskedPan.substring(creditCardDetails.maskedPan.length - 4);
                const expirationDate = creditCardDetails.expires;

                return (
                  <div
                    key={creditCardDetails.partnerIdUUID}
                    className={styles.cardContainer}
                    data-testid={`credit-card-list__container-${creditCardDetails.partnerIdUUID}_test-id`}
                  >
                    <RadioButton
                      data-testid={`credit-card-list__${creditCardDetails.partnerIdUUID}-radio-button_test-id`}
                      name={SELECTED_CREDIT_CARD}
                      id={`credit-card__${creditCardDetails.partnerIdUUID}`}
                      value={creditCardDetails.partnerIdUUID}
                      classes={{
                        icon: styles.radionIcon,
                        labelContent: styles.radioLabel,
                        label: styles.radioLabel,
                      }}
                      disabled={creditCardDetails.isExpired}
                      checked={
                        values?.selectedCreditCard === creditCardDetails.partnerIdUUID && !creditCardDetails.isExpired
                      }
                    />
                    <Icon
                      className={styles.icon}
                      title={creditCardDetails.cardType}
                      content={resolveCCImage(creditCardDetails.maskedPan)}
                    />
                    <div data-testid="credit-card-list__last-four-digits_test-id">- {last4Digits}</div>
                    <div
                      className={cx({ [styles.red]: creditCardDetails.isExpired })}
                      data-testid="credit-card-list__expiry_test-id"
                    >
                      {formatMessage({
                        id: creditCardDetails.isExpired ? 'expired' : 'expires',
                        values: { expirationDate },
                        defaultMessage: `${creditCardDetails.isExpired ? 'Expired' : 'Expires'} ${expirationDate}`,
                      })}
                    </div>
                    {creditCardDetails.isExpired && (
                      <Link
                        tag="button"
                        variant="underline"
                        onClick={(e: MouseEvent) => {
                          editCardModalHandler(e, creditCardDetails);
                        }}
                        textStyle="body-2"
                        data-testid={`credit-card-update__link-${creditCardDetails.partnerIdUUID}_test-id`}
                      >
                        {formatMessage({ id: 'update.card', defaultMessage: 'Update Card Details' })}
                      </Link>
                    )}
                  </div>
                );
              })}
              {showAddButton && (
                <div>
                  <Link
                    icon={plusIcon}
                    onClick={addCreditCardHandler}
                    tag="button"
                    variant="underline"
                    textStyle="body-1"
                  >
                    {formatMessage({ id: 'add.new.credit.card', defaultMessage: 'Add new credit card' })}
                  </Link>
                </div>
              )}
            </div>
            {billingAddressForSelectedCC && (
              <>
                <div className={styles.billingAddressTitle}>
                  {formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
                </div>
                <div className={styles.address}>
                  {getFormattedAddressinMultiline(billingAddressForSelectedCC, Boolean(isCSVFeatureFlagEnabled))}
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreditCardList;
