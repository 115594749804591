import { StudioData } from 'shared/types/nav';
import { Category } from 'shared/types/product/Category';
import HeaderContainer from 'components/commercetools-ui/organisms/lulu-header';
import { MarketProvider } from 'context/market';
import { NavProvider } from 'context/nav';

type Props = {
  data: StudioData;
  categories: Category[];
};

const LuluHeaderTastic = ({ data, categories }: Props) => {
  return (
    <MarketProvider>
      <NavProvider>
        <HeaderContainer studioData={data} categories={categories} />
      </NavProvider>
    </MarketProvider>
  );
};
export default LuluHeaderTastic;
