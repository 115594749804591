import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Icon, bagIcon } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { NgcCart } from 'shared/types/ngcCart';
import { useFormat } from 'helpers/hooks/useFormat';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import styles from './quick-view-bag.module.scss';
type QuickViewBagProps = {
  cartCount: number;
  hideBeacon?: boolean;
  cart?: NgcCart | null;
};

const QuickViewBag = ({ hideBeacon = false, cartCount }: QuickViewBagProps) => {
  // TODO later cartCount will get value from CartContext instead of prop
  // For test case support now added cartCount as a prop, later when getting data from context, then we will update test cases too.
  const [isBeaconVisible, setIsBeaconVisible] = useState(false);
  const ALERTTIME = 7000;
  const { formatMessage } = useFormat({ name: 'cart' });
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  useEffect(() => {
    if (hideBeacon) return;

    // For cartCount >= 1 only we will show beacon
    if ([0, null, undefined].includes(cartCount)) {
      setIsBeaconVisible(false);
      return;
    }
    const timer = setTimeout(() => {
      setIsBeaconVisible(true);
    }, ALERTTIME);
    return () => clearTimeout(timer);
  }, [cartCount]);

  const isCartCountValid = cartCount !== null && cartCount >= 0;

  const loadingMessage = formatMessage({ id: 'loading.items', defaultMessage: 'Loading items' });
  const cartCountMessage = isCartCountValid
    ? formatMessage({
        id: 'items.count',
        defaultMessage: `${cartCount} item(s) in the bag.`,
        values: { count: cartCount },
      })
    : loadingMessage;

  const cartHintMessage = formatMessage({
    id: 'items.hint',
    defaultMessage: `Click to view the bag.`,
  });

  const cartItemsCount = () => (cartCount !== undefined ? cartCount.toLocaleString() : '0');

  return (
    // we have used button tag below, so that enter key press can work on that.
    <Link
      href="/cart"
      className={styles.wrapper}
      onClick={() => {
        trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.MY_BAG_ICON_CLICK);
      }}
    >
      <a
        role="link"
        aria-label={cartCountMessage}
        aria-describedby={cartHintMessage}
        title={cartCountMessage}
        className={styles.wrapper}
        data-testid="quick-view-bag_test-id"
      >
        <div
          data-testid="quick-view-bag-icon_test-id"
          className={cs(styles.icon, {
            [styles.bagIndicator]: isBeaconVisible && !hideBeacon,
          })}
        >
          <Icon content={bagIcon} />
        </div>
        <span className={styles.count}>{cartItemsCount()}</span>
      </a>
    </Link>
  );
};

export default QuickViewBag;
